import create from "zustand";
import { persist } from "zustand/middleware";

const _storeName = 'customer'
const _storage = sessionStorage

const _raw = {
  id: null,
  data: null,
}

const useCustomer = create(
  persist(
    (set, get) => ({

      ..._raw,

      /**
       * Set customer id
       * @param {string} id - the id of the customer
       */
      setId: (id) => set(() => ({ id })),

      /**
       * set Customer data
       * @param {object} data - customer data (see _raw properties for description)
       */
      setData: (data) => set(() => ({ data })),

      /**
       * Check if store containt data
       * @returns {boolean} true if at least on of the property of the contain a non null data
       */
      hasData: () => {
        return get().data !== null;
      },

      /**
       * Check if data is persisted
       * @returns {boolean} true if data is persisted
       */
      hasStoredData: () => {
        return _storage.getItem(_storeName) !== null;
      },

      /**
       * Reset store has defaul data and delete persisted
       */
      reset: () => {
        set(() => ({ ..._raw }))
      }

    }),
    {
      name: _storeName,
      storage: _storage,
    }
  )
);

export { useCustomer, _storeName as StoreName };
