import React from "react";
import { useCustomer } from "../../store/customer/useCustomer";
import { useEstimate } from "../../store/estimate/useEstimate";
import {
  ContractDetailsTable,
  CustomerInfoDetailsTable,
  ProductsDetailsTable,
  ContractAdminOverides,
} from "../../components/details";

const ContractReport = () => {
  const estimate = useEstimate();
  const { data: customer } = useCustomer();

  return (
    <>
      {estimate && customer && (
        <>
          <ContractAdminOverides />
          <CustomerInfoDetailsTable customer={customer} />
          <ContractDetailsTable
            contractType={estimate.contractType}
            estimate={estimate}
          />
          <ProductsDetailsTable products={estimate.products} />
        </>
      )}
    </>
  );
};

export default ContractReport;
