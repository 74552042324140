import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { SelectButton } from "../../components/buttons";
import { StepsContext } from "../../components/stepper";
import { useEstimate } from "../../store/estimate/useEstimate";

const Operators = () => {
  const { validCurrentStep, inValidCurrentStep } = useContext(StepsContext);
  const { setOperator, operator } = useEstimate();
  const [selectedOperator, setSelectedOperator] = useState(operator);

  const availableOperators = [
    { operator: "orange", img: "/logo_operators/474px-Orange_logo.svg.png" },
    { operator: "sfr", img: "/logo_operators/600px-Logo_SFR_2014.svg.webp" },
    { operator: "bouygues", img: "/logo_operators/logo_bouygues_telecom.png" },
  ];

  const selectOperator = (name) => {
    setSelectedOperator(name);
  };

  useEffect(() => {
    if (selectedOperator) {
      setOperator(selectedOperator)
      validCurrentStep()
    } else {
      inValidCurrentStep()
    }
  }, [selectedOperator])

  return (
    <>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {availableOperators.map(({ operator, img }) => (
          <OperatorCart
            key={`key-${operator}`}
            imgUri={img}
            onSelect={() => selectOperator(operator)}
            isSelected={selectedOperator === operator}
          />
        ))}
      </div>
    </>
  );
};

function OperatorCart({ onSelect, isSelected, imgUri, children }) {
  return (
    <div className="w-full rounded overflow-hidden shadow-lg">
      <img className="w-full" src={imgUri} alt="opetator logo" />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{children}</div>
      </div>
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <SelectButton onSelect={onSelect} isSelected={isSelected}>
          Selectionner
        </SelectButton>
      </div>
    </div>
  );
}

export { Operators };
