import { useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import { StepsContext } from "./Stepper";

const StepperFormikConnect = () => {
  const { isValid, dirty, submitForm } = useFormikContext();
  const { validCurrentStep, inValidCurrentStep, setHandleNextStep } = useContext(StepsContext);

  setHandleNextStep(submitForm)

  useEffect(() => {
    const valid = isValid;
    valid ? validCurrentStep() : inValidCurrentStep();
  }, [isValid, dirty]);

  return null;
};

export { StepperFormikConnect }
