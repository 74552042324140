export function calculateTotalPrice({
  products,
  disableInstallationFees = false,
  contractType,
}) {
  var reducRecurent = {
    name: "",
    red: null,
  };

  products.map((item) => {
    if (item.name === "pourcentage_recurent") {
      let formatedReduc =
        item.reduction.length === 1
          ? "0.0" + item.reduction.replace(/\,|\./g, "")
          : "0." + item.reduction.replace(/\,|\./g, "");
      reducRecurent = {
        name: item.name,
        red: (1 - Number(formatedReduc)).toFixed(3),
      };
    }

    if (item.name === "euro_recurent") {
      reducRecurent = {
        name: item.name,
        red: item.reduction,
      };
    }
  });

  const totalHT = products.reduce(
    (previous, current) => {
      if (current.name === "euro_recurent") {
        return previous - current.reduction;
      }

      if (current.name === "pourcentage_recurent") {
        return previous;
      }

      const price = Number(current.price) * Number(current.quantity);
      const priceFrais = !disableInstallationFees
        ? Number(current.user_management_price) * Number(current.quantity)
        : 0;

      return previous + price + priceFrais;
    },
    contractType.slug === "contrat-tfcloud"
      ? Number(process.env.REACT_APP_PRICE_TFC_CLOUD_CONTRACT) || 0
      : 0
  );

  const totalHTFinal =
    reducRecurent.name === "pourcentage_recurent"
      ? totalHT * Number(reducRecurent.red)
      : totalHT;
  return {
    totalHT: totalHTFinal,
    totalTTC: totalHTFinal + totalHTFinal * 0.2,
  };
}
