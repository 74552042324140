import React, { useState, useEffect } from "react";
import { Field, Formik, Form } from "formik";
import { Button } from "../buttons";
import { useEstimate } from "../../store/estimate/useEstimate";
import { calculateTotalPrice } from "../../utils/priceCalculations";
import { roles, getUserRole } from "../../utils/userRoles";
import { useAuth0 } from "@auth0/auth0-react";
import { TextField } from "../utils";

const initialValues = {
  delete_installation_fees: false,
  add_recurring_rabate: false,
  recurring_rabate: 0,
  recurring_rabate_method: "euro",
  add_ponctual_rabate: false,
  ponctual_rabate: 0,
  ponctual_rabate_method: "euro",
};

export const ContractAdminOverides = () => {
  const [initial, setInitial] = useState(initialValues);
  const [recurringRabate, setRecurringRabate] = useState(
    initialValues.add_recurring_rabate
  );
  const [recurringRabateMethod, setRecurringRabateMethod] = useState(
    initialValues.recurring_rabate_method
  );
  const [deleteRecurringRabate, setDeleteRecurringRabate] = useState(false);
  const [haveRecurringRabate, setHaveRecurringRabate] = useState(false);
  const [ponctualRabate, setPonctualRabate] = useState(
    initialValues.add_ponctual_rabate
  );
  const [ponctualRabateMethod, setPonctualRabateMethod] = useState(
    initialValues.ponctual_rabate_method
  );
  const [deletePonctualRabate, setDeletePonctualRabate] = useState(false);
  const [havePonctualRabate, setHavePonctualRabate] = useState(false);
  const { user } = useAuth0();
  const {
    products,
    contractType,
    setTotalHT,
    setTotalTTC,
    bypassInstallationFees,
    setBypassInstallationFees,
  } = useEstimate();

  useEffect(() => {
    setInitial(
      Object.assign({}, initialValues, {
        delete_installation_fees: bypassInstallationFees,
      })
    );
  }, [bypassInstallationFees, setInitial]);

  useEffect(() => {
    products.map((_prod) => {
      if (_prod.name.includes("_recurent")) {
        setHaveRecurringRabate(true);
        setRecurringRabate(true);
        setInitial(
          Object.assign(initial, {
            recurring_rabate: Number(_prod.reduction),
          })
        );
        _prod.name.includes("euro")
          ? setRecurringRabateMethod("euro")
          : setRecurringRabateMethod("pourcentage");
      }

      if (_prod.name.includes("_ponctuel")) {
        setHavePonctualRabate(true);
        setPonctualRabate(true);
        setInitial(
          Object.assign(initial, {
            ponctual_rabate: Number(_prod.reduction),
          })
        );
        _prod.name.includes("euro")
          ? setPonctualRabateMethod("euro")
          : setPonctualRabateMethod("pourcentage");
      }
    });
  }, []);

  function removeRabate(name) {
    var indexDelete = null;

    products.map((item, index) => {
      if (item.name.includes(name)) {
        indexDelete = index;
      }
    });

    if (indexDelete !== null) {
      if (name.includes("_recurent")) {
        setHaveRecurringRabate(false);
        setDeleteRecurringRabate(false);
        setRecurringRabate(false);
        setRecurringRabateMethod("euro");
        setInitial(
          Object.assign({}, initial, {
            recurring_rabate: 0,
          })
        );
      }
      if (name.includes("_ponctuel")) {
        setHavePonctualRabate(false);
        setDeletePonctualRabate(false);
        setPonctualRabate(false);
        setPonctualRabateMethod("euro");
        setInitial(
          Object.assign({}, initial, {
            ponctual_rabate: 0,
          })
        );
      }

      products.splice(indexDelete, 1);
    }
  }

  function handleSubmit(values) {
    var delet = false;
    if (deleteRecurringRabate === false && delet === false) {
      if (recurringRabate === true) {
        haveRecurringRabate === false
          ? products.push({
              content: "<p>Remise récurrente</p>",
              date: new Date(),
              id: Math.random(10000),
              image: "rabates",
              management_price: 0,
              name: recurringRabateMethod + "_recurent",
              price: 0,
              product_file: false,
              quantity: 1,
              slug: "remise-reccurente",
              summary: "Remise récurrente",
              thumbnail: "rabates",
              title: "Remise récurrente",
              user_management_price: 0,
              reduction: String(values.recurring_rabate),
            })
          : products.map((item) => {
              if (item.name.includes("_recurent")) {
                item.name = recurringRabateMethod + "_recurent";
                item.reduction = String(values.recurring_rabate);
              }
            });
        setHaveRecurringRabate(true);
      }
    } else if (delet === false) {
      delet = true;
      removeRabate("_recurent");
    }

    if (deletePonctualRabate === false && delet === false) {
      if (ponctualRabate === true) {
        havePonctualRabate === false
          ? products.push({
              content: "<p>Remise ponctuelle</p>",
              date: new Date(),
              id: Math.random(10000),
              image: "rabates",
              management_price: 0,
              name: ponctualRabateMethod + "_ponctuel",
              price: 0,
              product_file: false,
              quantity: 1,
              slug: "remise-ponctuelle",
              summary: "Remise ponctuelle",
              thumbnail: "rabates",
              title: "Remise ponctuelle",
              user_management_price: 0,
              reduction: String(values.ponctual_rabate),
            })
          : products.map((item) => {
              if (item.name.includes("_ponctuel")) {
                item.name = ponctualRabateMethod + "_ponctuel";
                item.reduction = String(values.ponctual_rabate);
              }
            });

        setHavePonctualRabate(true);
      }
    } else if (delet === false) {
      delet = true;
      removeRabate("_ponctuel");
    }

    const { totalHT, totalTTC } = calculateTotalPrice({
      products,
      contractType,
      disableInstallationFees: values.delete_installation_fees,
    });

    setTotalHT(totalHT.toFixed(2));
    setTotalTTC(totalTTC.toFixed(2));
    setBypassInstallationFees(values.delete_installation_fees);
  }

  function displayIfAdminOrExternal() {
    if (
      !user ||
      ![roles.ADMIN, roles.EXTERNAL].includes(getUserRole({ user }))
    ) {
      return "";
    }

    return (
      <div className="bg-white shadow overflow-hidden rounded-lg mb-4 last:mb-0">
        <div className="py-5 border-b border-gray-200 px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Modifications
          </h3>
        </div>
        <div>
          <Formik
            initialValues={initial}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            <Form>
              <div className="flex px-4 py-5">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    htmlFor="delete_installation_fees"
                    className="block text-gray-500"
                  >
                    <Field
                      type="checkbox"
                      name="delete_installation_fees"
                      id="delete_installation_fees"
                      className="mr-2 leading-tight"
                    />
                    Supprimer les frais de mise en service
                  </label>
                  <label
                    htmlFor="add_recurring_rabate"
                    className="block text-gray-500"
                  >
                    <Field
                      type="checkbox"
                      name="add_recurring_rabate"
                      id="add_recurring_rabate"
                      className="mr-2 leading-tight"
                      checked={recurringRabate}
                      onChange={() => setRecurringRabate(!recurringRabate)}
                    />
                    Remise récurrente
                    {recurringRabate === true && (
                      <div className="flex mt-3">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <TextField name="recurring_rabate">
                            Montant de la remise
                          </TextField>
                        </div>
                        <div
                          role="group"
                          aria-labelledby="my-radio-group"
                          className="p-2 flex items-end mb-4"
                        >
                          <label className="mr-3">
                            <Field
                              type="checkbox"
                              name="recurring_rabate_method"
                              id="recurring_rabate_method"
                              className="mr-2 leading-tight"
                              checked={recurringRabateMethod === "euro"}
                              onChange={() => setRecurringRabateMethod("euro")}
                            />
                            Euro(s)
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="recurring_rabate_method"
                              id="recurring_rabate_method"
                              className="mr-2 leading-tight"
                              checked={recurringRabateMethod === "pourcentage"}
                              onChange={() =>
                                setRecurringRabateMethod("pourcentage")
                              }
                            />
                            Pourcentage(s)
                          </label>
                        </div>
                        <div className="self-center ml-2 mt-3">
                          <Button
                            onClick={() => setDeleteRecurringRabate(true)}
                            color="red-500"
                            hoverColor="red-600"
                            focusColor="red-700"
                          >
                            Supprimer
                          </Button>
                        </div>
                      </div>
                    )}
                  </label>
                  <label
                    htmlFor="add_ponctual_rabate"
                    className="block text-gray-500"
                  >
                    <Field
                      type="checkbox"
                      name="add_ponctual_rabate"
                      id="add_ponctual_rabate"
                      className="mr-2 leading-tight"
                      checked={ponctualRabate}
                      onChange={() => setPonctualRabate(!ponctualRabate)}
                    />
                    Remise ponctuelle
                    {ponctualRabate === true && (
                      <div className="flex mt-3">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <TextField name="ponctual_rabate">
                            Montant de la remise
                          </TextField>
                        </div>
                        <div
                          role="group"
                          aria-labelledby="my-radio-group"
                          className="p-2 flex items-end mb-4"
                        >
                          <label className="mr-3">
                            <Field
                              type="checkbox"
                              name="ponctual_rabate_method"
                              id="ponctual_rabate_method"
                              className="mr-2 leading-tight"
                              checked={ponctualRabateMethod === "euro"}
                              onChange={() => setPonctualRabateMethod("euro")}
                            />
                            Euro(s)
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="ponctual_rabate_method"
                              id="ponctual_rabate_method"
                              className="mr-2 leading-tight"
                              checked={ponctualRabateMethod === "pourcentage"}
                              onChange={() =>
                                setPonctualRabateMethod("pourcentage")
                              }
                            />
                            Pourcentage(s)
                          </label>
                        </div>
                        <div className="self-center ml-2 mt-3">
                          <Button
                            onClick={() => setDeletePonctualRabate(true)}
                            color="red-500"
                            hoverColor="red-600"
                            focusColor="red-700"
                          >
                            Supprimer
                          </Button>
                        </div>
                      </div>
                    )}
                  </label>
                </div>
              </div>
              <div className="flex items-center justify-end px-4 pb-5">
                <Button type="submit">Valider</Button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    );
  }

  return displayIfAdminOrExternal();
};
