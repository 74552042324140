import { useEffect, useState } from "react";

// TODO use useStateMap

const useValidSteps = () => {
  const [valids, setValids] = useState(() => {
    const storedValidSteps = window.sessionStorage.getItem("validSteps");
    if (storedValidSteps) return new Map(JSON.parse(storedValidSteps));

    return new Map();
  });

  const validStep = (step) => {
    setValids(new Map(valids.set(step.stepIndex, true)));
  };

  const invalidStep = (step) => {
    if (valids.delete(step.stepIndex)) {
      setValids(new Map(valids));
    }
  };

  const checkValidStep = (step) => {
    if (valids.has(step.stepIndex)) {
      return valids.get(step.stepIndex);
    }
    return false;
  };

  const checkConsistence = (steps) => {
    valids.forEach((_, key) => {
      if (!steps[key]) {
        valids.delete(key);
      }
    });
    setValids(new Map(valids));
  };

  const resetValidsSteps = () => {
    window.sessionStorage.removeItem('validSteps')
  }

  useEffect(() => {
    valids &&
      window.sessionStorage.setItem(
        "validSteps",
        JSON.stringify(Array.from(valids))
      );
  }, [valids]);

  return { validStep, invalidStep, checkValidStep, checkConsistence, resetValidsSteps };
};

export { useValidSteps };
