import { isNil } from 'lodash';

function extract(item) {
  const tab = [];
  Object.entries(item).forEach((entry) => {
    const [, value] = entry;
    if (typeof value !== "string" && !isNil(value)) {
      tab.push(...extract(value));
      return;
    }
    tab.push(value);
    return;
  });
  return tab;
}

export { extract };
