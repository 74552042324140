import React from 'react';
import classNames from 'classnames';

const Container = ({ children, className, ...args }) => {
  const _class = classNames(
    "w-11/12 mx-auto",
    className
  )
  return (
    <div className={_class} {...args}>
      { children}
    </div>
  );
}

export default Container;
