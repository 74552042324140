import React, { useContext } from "react";
import { StepsContext } from "./Stepper";
import { StepHeaderElem } from "./StepHeaderElem";

const StepHeader = () => {
  const { steps, isStepDisabled, isStepActive, goToStep } = useContext(
    StepsContext
  );
  return (
    <header className="mx-4 p-4">
      <div className="flex items-center">
        {steps.map((step, i) => {
          return (
            <StepHeaderElem
              key={`step-${step.name}`}
              name={step.name}
              isEdge={step.stepIndex !== 0}
              isDisabled={isStepDisabled(step)}
              isActive={isStepActive(step)}
              goTo={() => goToStep(step)}
            />
          );
        })}
      </div>
    </header>
  );
};

export { StepHeader };
