import create from "zustand";
import { persist } from "zustand/middleware";

const _storeName = "estimate";
const _storage = sessionStorage;

const _raw = {
  id: null,
  contractType: null,
  portability: null,
  rio: null,
  phoneNumber: null,
  operator: null,
  products: [],
  totalHT: null,
  totalTTC: null,
  bypassInstallationFees: false,
  activateFeeMultiplier: false,
};

const useEstimate = create(
  persist(
    (set, get) => ({
      ..._raw,

      setId: (id) => set((state) => ({ id })),
      setPortability: (portability) => set((state) => ({ portability })),
      setRio: (rio) => set((state) => ({ rio })),
      setPhoneNumber: (phoneNumber) => set((state) => ({ phoneNumber })),
      setContractType: (type) => set((state) => ({ contractType: type })),
      setOperator: (name) => set((state) => ({ operator: name })),
      setProducts: (products) => set((state) => ({ products })),
      setTotalHT: (totalHT) => set((state) => ({ totalHT })),
      setTotalTTC: (totalTTC) => set((state) => ({ totalTTC })),
      // used to bypass installation fees in the quote
      setBypassInstallationFees: (bypassInstallationFees) =>
        set((state) => ({ bypassInstallationFees })),

      // Store management
      onEdit: false,
      setOnEdit: (onEdit) => set((state) => ({ onEdit })),
      /**
       * Check if store containt data
       * @returns {boolean} true if at least on of the property of the contain a non null data
       */
      hasData: () => {
        return JSON.stringify(_raw) !== JSON.stringify(get());
      },

      /**
       * Check if data is persisted
       * @returns {boolean} true if data is persisted
       */
      hasStoredData: () => {
        return _storage.getItem(_storeName) !== null;
      },

      /**
       * Reset store has defaul data and delete persisted
       */
      reset: () => {
        set(() => ({ ..._raw, onEdit: false }));
      },

      /**
       * Set all data in store
       */
      bulk: (bulkData) => {
        set(() => Object.assign({}, _raw, bulkData));
      },
    }),
    { name: _storeName, storage: _storage }
  )
);

export { useEstimate, _storeName as StoreName };
