import React from "react";

const ModalContent = ({ children }) => {
  return (
    <>
      <span className="inline-block align-middle h-screen"></span>&#8203;
      { children}
    </>
  );
};

export { ModalContent };
