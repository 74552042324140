import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../components/alerts";
import { Card, CardContent, CardTitle } from "../components/carts";
import {
  ContractDetailsTable,
  CustomerInfoDetailsTable,
  ProductsDetailsTable,
} from "../components/details";
import Menu from "../components/Menu/Menu";
import { ConfirmationModal, ModalBox } from "../components/Modal";
import { Paging } from "../components/paging/paging";
import { Spinner } from "../components/spinner/Spinner";
import { SearchBar } from "../components/utils";
import { ProductModalContextProvider } from "../contexts/productModalContext";
import { useCustomer } from "../store/customer/useCustomer";
import { useEstimate } from "../store/estimate/useEstimate";
import { useAuthHeader } from "../utils/auth/useAuthHeader";
import { extract } from "../utils/Extract";
import { downloadFilledEstimatePdf } from "../utils/pdfProcess";
import Fuse from "fuse.js";

const EstimateManager = () => {
  const {
    setOnEdit,
    bulk: setEstimateData,
    setId: setEstimateId,
    setContractType,
  } = useEstimate();
  const { setData: setCustomerData, setId: setCustomerId } = useCustomer();
  const { push } = useHistory();
  const alertManager = useContext(AlertContext);

  const [estimates, setEstimates] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [categories, setCategories] = useState(null);

  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [estimateToDelete, setEstimateToDelete] = useState(null);
  const [detailModalOpened, setDetailModalOpened] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [loading, setLoading] = useState(false);

  const { getAuthHeader, isAuthenticated } = useAuthHeader();
  const { user, isLoading } = useAuth0();

  const loadData = () => {
    async function getEstimates(options) {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/estimates`,
        options
      );
      return result.data;
    }
    async function getCustomers(options) {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/clients`,
        options
      );
      return result.data;
    }
    async function getCategories(options) {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/wp-proxy/categories`,
        options
      );
      return result.data;
    }
    return getAuthHeader().then((authHeader) => {
      const options = { headers: authHeader };
      return getEstimates(options)
        .then((estimates) => {
          setEstimates(estimates);
          return getCustomers(options);
        })
        .then((customers) => {
          setCustomers(customers);
          return getCategories(options);
        })
        .then((categories) => {
          setCategories(categories);
        });
    });
  };

  useEffect(() => {
    if (isLoading || isAuthenticated) return;

    push("/login");
  }, [isAuthenticated, isLoading]);

  // Loading data
  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      setLoading(true);
      loadData()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          alertManager.riseAlert({
            title: "Oups ! Une erreur c'est produite",
            info: "Une erreur lors de la récuparation des données c'est produite. Rafraichir la page pour ré-éssayer (ctrl/cmd + r).",
          });
        });
    }
  }, [isLoading]);

  // Delete management ----------------------------------------------------
  const askestiateDelete = (estimate) => {
    setDeleteModalStatus(true);
    setEstimateToDelete(estimate);
  };
  const confirmEstimateToDelete = (confirm) => {
    if (confirm) {
      deleteEstimate(estimateToDelete);
    }
    setEstimateToDelete(null);
    setDeleteModalStatus(false);
  };
  const deleteEstimate = async ({ estimateId, estimate }) => {
    const authHeader = await getAuthHeader();
    const options = { headers: authHeader };

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/estimates/${estimateId}`,
        options
      )
      .then((result) => {
        if (result.status === 200) {
          deleteLocalEstimate(estimateId);
        }
        return false;
      });
  };
  const deleteLocalEstimate = (estimateToDeleteId) => {
    setEstimates((estimates) => {
      return estimates.filter((estimate) => estimate.id !== estimateToDeleteId);
    });
  };
  // ----------------------------------------------------------------------

  // Details management ---------------------------------------------------
  const openModal = (data) => {
    setModalData(data);
    setDetailModalOpened(true);
  };
  // ----------------------------------------------------------------------

  // Edit management ------------------------------------------------------
  const editMode = (data) => {
    // Init edit mode
    setOnEdit(true);
    // Set date on store
    setEstimateData(data.estimate);
    setEstimateId(data.estimateId);
    setCustomerData(data.customer);
    setCustomerId(data.customerId);
    setContractType(data.contractType);
    // Go stepper
    push("/estimate");
  };
  // -----------------------------------------------------------------------

  return (
    <>
      <Menu />

      {loading && <Spinner />}

      {estimates && customers && categories && !loading && (
        <EstimateTable
          data={{ estimates, customers, categories }}
          onDelete={askestiateDelete}
          onEdit={editMode}
          onDetails={openModal}
          onDownload={(estimate, customer, contractType) => {
            estimate.contractType = contractType;
            downloadFilledEstimatePdf(user, estimate, customer);
          }}
        />
      )}

      <ConfirmationModal
        title="Suppression de devis"
        open={deleteModalStatus}
        onConfirm={() => confirmEstimateToDelete(true)}
        onCancel={() => confirmEstimateToDelete(false)}
      >
        Êtes-vous sûr de vouloir supprimer ce devis ? Toutes vos données seront
        définitivement supprimées. Cette action ne peut être annulée.
      </ConfirmationModal>
      <ProductModalContextProvider>
        <ModalBox open={detailModalOpened}>
          {modalData && (
            <Card
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
              className="transform transition-all align-middle max-w-2xl w-2/3"
            >
              <CardContent>
                <CardTitle>
                  <h3 className="text-xl font-bold uppercase text-center text-white bg-blue-500 py-2 mb-4 rounded-full align-middle">
                    Details du devis
                  </h3>
                </CardTitle>
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <CustomerInfoDetailsTable customer={modalData.customer} />
                  </div>
                  <div>
                    <ContractDetailsTable
                      contractType={modalData.contractType}
                      estimate={modalData.estimate}
                    />
                    <ProductsDetailsTable
                      products={modalData.estimate.products}
                    />
                  </div>
                </div>
              </CardContent>
              {/* // TODO make card footer comp */}
              <div className="bg-gray-50 py-3 px-6 flex flex-row-reverse">
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button
                    onClick={() => {
                      setDetailModalOpened(false);
                    }}
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  >
                    Close
                  </button>
                </span>
              </div>
            </Card>
          )}
        </ModalBox>
      </ProductModalContextProvider>
    </>
  );
};

// TODO isolate component

const EstimateTable = ({ data, onDelete, onEdit, onDetails, onDownload }) => {
  const [search, setSearch] = useState("");
  const [paging, setPaging] = useState(0);
  const [filteredEstimates, setFilteredEstimates] = useState([]);

  /* paging infos */
  const estimatePerPage = 15;

  const { estimates, customers, categories } = data;
  const getCustomer = (id) => customers.find((customer) => customer.id === id);
  const getContractType = (id) => categories.find((cat) => cat.id === id);

  useEffect(() => {
    if (estimates) {
      const result = estimates
        .map((_estimate) => {
          const { data: estimate, id: estimateId } = _estimate;
          const { data: customer, id: customerId } = getCustomer(
            estimate.customerId
          );

          const contractType = getContractType(estimate.contractId);

          return {
            customer,
            customerId,
            estimate,
            estimateId,
            contractType,
          };
        })
        .filter((item) => {
          const stringItem = extract(item).join(" ").toLocaleLowerCase();
          return stringItem.includes(search.toLocaleLowerCase());
        });

      setFilteredEstimates(result);
    }
  }, [estimates, search, paging]);

  useEffect(() => {
    if (paging > Math.floor(filteredEstimates.length / estimatePerPage)) {
      setPaging(0);
    }
  }, [search, filteredEstimates]);

  function updatePaging(number) {
    if (
      number > Math.floor(filteredEstimates.length / estimatePerPage) ||
      number < 0
    )
      return;

    setPaging(number);
  }

  return (
    <>
      <div className="flex flex-col mx-auto w-11/12 mt-8">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 sm:px-6 lg:px-8">
            <SearchBar name="devis" search={search} setSearch={setSearch} />
          </div>
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="table-fixed w-full divide-y divide-gray-200">
                <EstimateTableHeader>
                  <EstimateTableHeaderElement className="w-1/5">
                    Name
                  </EstimateTableHeaderElement>
                  <EstimateTableHeaderElement className="w-1/4">
                    Entreprise
                  </EstimateTableHeaderElement>
                  <EstimateTableHeaderElement className="w-1/6">
                    Contact commercial
                  </EstimateTableHeaderElement>
                  <EstimateTableHeaderElement className="w-1/6">
                    Date
                  </EstimateTableHeaderElement>
                  <EstimateTableHeaderElement className="w-1/6">
                    Type de contract
                  </EstimateTableHeaderElement>
                  <EstimateTableHeaderElement className="w-1/12">
                    Total (HT)
                  </EstimateTableHeaderElement>
                  <EstimateTableHeaderElement className="w-1/12">
                    Total (TTC)
                  </EstimateTableHeaderElement>
                  <EstimateTableHeaderElement className="w-1/4"></EstimateTableHeaderElement>
                </EstimateTableHeader>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredEstimates &&
                    [...filteredEstimates]
                      .reverse()
                      .slice(
                        estimatePerPage * paging,
                        estimatePerPage * paging + estimatePerPage
                      )
                      .map((_estimate) => {
                        const { estimate, estimateId } = _estimate;
                        const { customer, customerId } = _estimate;
                        const { contractType } = _estimate;

                        const data = {
                          customer,
                          customerId,
                          estimate,
                          estimateId,
                          contractType,
                        };

                        const date = moment(estimate.date, "YYYY-MM-DD").format(
                          "LL"
                        );

                        return (
                          <EstimateTableRow key={estimateId}>
                            <EstimateTableCellWidthSub
                              data={`${customer.firstname} ${customer.lastname}`}
                              subData={customer.email}
                            />
                            <EstimateTableCellWidthSub
                              data={customer.company_name}
                              subData={customer.adress}
                            />
                            <EstimateTableCell data={estimate.agentName} />
                            <EstimateTableCell data={date} />
                            <EstimateTableCell>
                              <Badge>{contractType.name}</Badge>
                            </EstimateTableCell>
                            <EstimateTableCell
                              className="overflow-x-hidden"
                              data={estimate.totalHT}
                            />
                            <EstimateTableCell
                              className="overflow-x-hidden"
                              data={estimate.totalTTC}
                            />
                            <EstimateTableCell>
                              <button
                                onClick={() => onDetails(_estimate)}
                                className="text-indigo-600 hover:text-indigo-900 mr-4"
                              >
                                Détails
                              </button>
                              <button
                                onClick={() => onEdit(_estimate)}
                                className="text-indigo-600 hover:text-indigo-900 mr-4"
                              >
                                Editer
                              </button>
                              <button
                                onClick={() =>
                                  onDelete({ estimateId, estimate })
                                }
                                className="text-red-600 hover:text-indigo-900 mr-4"
                              >
                                Supprimer
                              </button>
                              <button
                                onClick={() =>
                                  onDownload(estimate, customer, contractType)
                                }
                                className="text-green-600 hover:text-indigo-900 mr-4 last:mr-0 text-xl align-middle"
                              >
                                <FiDownload />
                              </button>
                            </EstimateTableCell>
                          </EstimateTableRow>
                        );
                      })}
                </tbody>
              </table>
            </div>
            <Paging
              updatePaging={updatePaging}
              paging={paging}
              maxPages={Math.floor(filteredEstimates.length / estimatePerPage)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const EstimateTableHeader = ({ children }) => {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
};
const EstimateTableHeaderElement = ({ children, className }) => {
  return (
    <th
      className={`px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider ${className}`}
    >
      {children}
    </th>
  );
};

const EstimateTableRow = ({ children }) => {
  return <tr>{children}</tr>;
};

const EstimateTableCell = ({ children, data, className }) => {
  return (
    <td
      className={`px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 ${className}`}
    >
      {" "}
      {children ? children : data}{" "}
    </td>
  );
};

const EstimateTableCellWidthSub = ({ data, subData }) => {
  return (
    <td className="px-6 py-4 whitespace-no-wrap truncate">
      <div className="text-sm leading-5 font-medium text-gray-900">{data}</div>
      <div className="text-sm leading-5 text-gray-500">{subData}</div>
    </td>
  );
};

const Badge = ({ children }) => {
  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
      {children}
    </span>
  );
};

export { EstimateManager };
