import React, { useContext } from "react";
import { ModalContext } from "../../contexts/productModalContext";
import { ProductCart } from "../carts";
import ProductGrid from "../ProductGrid/ProductGrid";

export const ProductsDetailsTable = ({ products, colNumber }) => {
  const { openSelectedProduct } = useContext(ModalContext);
  return (
    <div className="bg-white shadow overflow-hidden rounded-lg mb-4 last:mb-0">
      <div className="py-5 border-b border-gray-200 px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Produits</h3>
      </div>
      <div className="px-2 py-4">
        {!products && <p className="py-2 text-center">Aucun produits à afficher</p>}

        {/* <div className="grid grid-cols-2 gap-4">
          {products && products.map((product) => (
            <ProductCart key={product.id} item={product} onOpen={() => openSelectedProduct({ item: product })} />
          ))}
        </div> */}

        <ProductGrid products={products} openSelectedProduct={openSelectedProduct} colNumber={2} />

      </div>
    </div>
  );
};
