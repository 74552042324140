/**
 * Stepper componant
 * version: 1.2.3
 * Author: Nicolas Liveris
 * Date: Octobre 2020
 */

import React, { createContext, useEffect } from "react";
import { StepFooter } from "./stepFooter";
import { StepHeader } from "./StepHeader";
import { useStepperList } from "./stepperList";
import { useStepperutils } from "./stepperUtils";

const StepsContext = createContext();

const Stepper = function ({ children, onStepperDone, hidePrevButton, hideNextButton }) {
  const stepperList = useStepperList(children);
  const { filterFooterAction, extractFooterAction } = useStepperutils()
  const child = filterFooterAction(children)
  const footerActions = extractFooterAction(children)

  return (
    <>
      <StepsContext.Provider value={{ ...stepperList, onStepperDone, hidePrevButton, hideNextButton }}>
        <div className="p-5 h-full flex flex-col">
          <StepHeader />
          <div className="mt-8 p-4 flex-grow flex flex-col">
            <main className="flex-grow">{child}</main>
            <StepFooter>{footerActions}</StepFooter>
          </div>
        </div>
      </StepsContext.Provider>
    </>
  );
};

export { Stepper, StepsContext };
