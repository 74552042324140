import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

import Logo from "../logo.jpg";

const Login = function () {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { push } = useHistory();

  useEffect(() => {
    if (isAuthenticated) push("/");
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      <div className="flex flex-row justify-center items-center h-screen overflow-hidden topographic-bg ">
        <div className="w-full md:w-1/2 lg:w-1/3 shadow-none h-screen flex flex-col justify-center items-center px-8">
          <div className="w-full flex flex-col justify-center items-center bg-white pt-8 rounded-lg shadow-lg overflow-hidden">
            <div className="py-12 flex flex-col xl:flex-row justify-center items-center flex-wrap px-4">
              <img className="w-64" src={Logo} alt="Logo" />
            </div>
            <div className="w-full">
              <a
                role="button"
                type="button"
                className="w-full px-6 py-8 shadow-2xl bg-blue-500 text-white uppercase font-semibold flex flex-row justify-center items-center"
                onClick={loginWithRedirect}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-5 text-white fill-current mr-2"
                >
                  <path d="M7 10V7a5 5 0 1 1 10 0v3h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h2zm2 0h6V7a3 3 0 0 0-6 0v3zm-4 2v8h14v-8H5zm7 2a1 1 0 0 1 1 1v2a1 1 0 0 1-2 0v-2a1 1 0 0 1 1-1z" />
                </svg>
                Se connecter
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export { Login };
