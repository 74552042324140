import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ContractCart } from "../../components/carts";
import { StepsContext } from "../../components/stepper/Stepper";
import { useEstimate } from "../../store/estimate/useEstimate";
import { useAuthHeader } from "../../utils/auth/useAuthHeader";
import { Spinner } from "../../components/spinner/Spinner";
import { AlertContext } from "../../components/alerts";

const ChooseContract = () => {
  const { validCurrentStep } = useContext(StepsContext);
  const { getAuthHeader, isLoading } = useAuthHeader();
  const { contractType, setContractType } = useEstimate();
  const alertManager = useContext(AlertContext);

  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadContracts = () => {
    async function getContracts(options) {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/wp-proxy/categories`,
        options
      );
    }

    return getAuthHeader().then((authHeader) => {
      const options = { headers: authHeader };
      return getContracts(options);
    });
  };

  useEffect(() => {
    if (!isLoading) {
      setLoading(true);
      loadContracts()
        .then((contracts) => {
          setContracts(contracts.data);
          setLoading(false);
        })
        .catch(() => {
          alertManager.riseAlert({
            title: "Oups ! Une erreur c'est produite",
            info:
              "Une erreur lors de la récuparation des données c'est produite. Rafraichir la page pour ré-éssayer (ctrl/cmd + r).",
          });
        });
    }
  }, [isLoading]);

  useEffect(() => {
    if (contractType) validCurrentStep();
  }, [contractType]);

  const selectContract = (contract) => {
    setContractType(contract);
  };

  return (
    <>
      {loading && <Spinner />}
      {contracts && (
        <div className="grid grid-cols-3 gap-8 my-16">
          {contracts.map((contract) => (
            <ContractCart
              key={contract.id}
              title={contract.name}
              description={contract.description}
              onSelect={() => selectContract(contract)}
              isSelected={contract.id === (contractType && contractType.id)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export { ChooseContract };
