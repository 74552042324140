import axios from "axios";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Menu from "../components/Menu/Menu";
import {
  Step,
  Stepper,
  useValidSteps,
  StepperFooterAction,
} from "../components/stepper";
import { ProductModalContextProvider } from "../contexts/productModalContext";
import useShoppingBasket from "../store/Panier/Panier";
import { useAuthHeader } from "../utils/auth/useAuthHeader";

import {
  CustomerInfo,
  ChooseContract,
  ExistingNumber,
  Offers,
  Operators,
  Portability,
  Summary,
  ProductSelection,
} from "./steps-views";

import moment from "moment";
import { Button } from "../components/buttons";
import { ConfirmationModal } from "../components/Modal";
import { useEstimate } from "../store/estimate/useEstimate";
import { useCustomer } from "../store/customer/useCustomer";
import { AlertContext } from "../components/alerts";
import Container from "../components/utils/Container";

const usePersistance = () => {
  const { getAuthHeader } = useAuthHeader();

  const saveCustomer = async (customer) => {
    if (!customer) throw Error("customer should provided to save");
    return await getAuthHeader().then((authHeader) => {
      const options = { headers: authHeader };
      return axios.post(
        `${process.env.REACT_APP_API_URL}/clients`,
        customer,
        options
      );
    });
  };

  const UpdateCustomer = async (customer, id) => {
    if (!id) throw Error("id should provided to update customee");
    if (!customer) throw Error("customer should provided to update");
    return await getAuthHeader().then((authHeader) => {
      const options = { headers: authHeader };
      return axios.patch(
        `${process.env.REACT_APP_API_URL}/clients/${id}`,
        customer,
        options
      );
    });
  };

  const saveEstimate = async (estimate) => {
    if (!estimate) throw Error("estimate should provided to update");
    return await getAuthHeader().then((authHeader) => {
      const options = { headers: authHeader };
      return axios.post(
        `${process.env.REACT_APP_API_URL}/estimates`,
        estimate,
        options
      );
    });
  };

  const UpdateEstimate = async (estimate, id) => {
    if (!id) throw Error("id should provided to update customee");
    if (!estimate) throw Error("estimate should provided to update");
    const data = Object.assign({}, estimate);
    return await getAuthHeader().then((authHeader) => {
      const options = { headers: authHeader };
      return axios.patch(
        `${process.env.REACT_APP_API_URL}/estimates/${id}`,
        data,
        options
      );
    });
  };

  return {
    saveEstimate,
    saveCustomer,
    UpdateEstimate,
    UpdateCustomer,
  };
};

const Estimate = () => {
  const alertManager = useContext(AlertContext);
  const {
    saveCustomer,
    saveEstimate,
    UpdateCustomer,
    UpdateEstimate,
  } = usePersistance(alertManager);

  const { pathname } = useLocation();
  const { push } = useHistory();
  const { resetValidsSteps } = useValidSteps();

  let estimateStore;
  const {
    reset: resetEstimate,
    contractType,
    portability,
    onEdit,
    id: estimateId,
  } = (estimateStore = useEstimate());
  const {
    reset: resetCustomer,
    data: customer,
    id: customerId,
  } = useCustomer();
  const { reset: resetPanier } = useShoppingBasket();

  const [cancelModalStatut, setCancelModalStatut] = useState(false);

  const stepperDone = () => {
    if (onEdit && customerId) {
      const estimateData = {
        bypassInstallationFees: estimateStore.bypassInstallationFees,
        contractId: estimateStore.contractType.id,
        customerId: customerId,
        date: estimateStore.date,
        operator: estimateStore.operator,
        phoneNumber: estimateStore.phoneNumber,
        products: estimateStore.products,
        rio: estimateStore.rio,
        totalHT: estimateStore.totalHT,
        totalTTC: estimateStore.totalTTC,
      };
      UpdateCustomer(customer, customerId)
        .then(() => {
          return UpdateEstimate(estimateData, estimateId);
        })
        .then(() => {
          resetModification();
          push("/estimate-manager");
        })
        .catch(() => {
          alertManager.riseAlert({
            title: "Oups ! Une erreur c'est produite",
            info:
              "Une erreur lors de la mise à jour des données c'est produite. Merci de rééssayer.",
          });
        });
    } else {
      saveCustomer(customer)
        .then((result) => {
          const customerId = result.data.id;
          const data = {
            bypassInstallationFees: estimateStore.bypassInstallationFees,
            contractId: contractType.id,
            customerId: customerId,
            date: moment().format("YYYY-MM-DD"),
            operator: estimateStore.operator,
            phoneNumber: estimateStore.phoneNumber,
            products: estimateStore.products,
            rio: estimateStore.rio,
            totalHT: estimateStore.totalHT,
            totalTTC: estimateStore.totalTTC,
          };
          return saveEstimate(data);
        })
        .then(() => {
          resetModification();
          push("/estimate-manager");
        })
        .catch(() => {
          alertManager.riseAlert({
            title: "Oups ! Une erreur c'est produite",
            info:
              "Une erreur lors de la sauvegarde des données c'est produite. Merci de rééssayer.",
          });
        });
    }
  };

  const stepperCancel = () => {
    resetModification();
    setCancelModalStatut(false);
    push("/estimate-manager");
  };

  const resetModification = () => {
    resetCustomer();
    resetEstimate();
    resetPanier();
    resetValidsSteps();
  };

  return (
    <>
      <Menu></Menu>
      <Container className="flex-grow">
        <Stepper
          hideNextButton={pathname === "/estimate/product-selection"}
          onStepperDone={stepperDone}
        >
          <Step name="Info Client" stepPath="customer-info" isSkipable={false}>
            <CustomerInfo />
          </Step>
          <Step
            name="Type de contrat"
            stepPath="choose-contract"
            isSkipable={false}
          >
            <ChooseContract />
          </Step>
          {contractType && contractType.slug === "mobiles" && (
            <>
              <Step
                name="Portabilité"
                stepPath="portability"
                isSkipable={false}
              >
                <Portability />
              </Step>
              {portability !== null && portability && (
                <Step
                  name="Numéro existant"
                  stepPath="existing-number"
                  isSkipable={false}
                >
                  <ExistingNumber />
                </Step>
              )}
              <Step name="Offres" stepPath="offers" isSkipable={false}>
                <ProductModalContextProvider>
                  <Offers />
                </ProductModalContextProvider>
              </Step>
              <Step name="Opérateurs" stepPath="operator" isSkipable={false}>
                <Operators />
              </Step>
            </>
          )}
          {contractType && contractType.slug !== "mobiles" && (
            <Step
              name="Selection des Produits"
              stepPath="product-selection"
              isSkipable={false}
            >
              <ProductModalContextProvider>
                <ProductSelection />
              </ProductModalContextProvider>
            </Step>
          )}
          <Step name="Récapitulatif" stepPath="summary" isSkipable={true}>
            <ProductModalContextProvider>
              <Summary />
            </ProductModalContextProvider>
          </Step>
          <StepperFooterAction>
            <Button
              onClick={() => setCancelModalStatut(true)}
              color="red-500"
              hoverColor="red-600"
              focusColor="red-700"
            >
              Annuler
            </Button>
          </StepperFooterAction>
        </Stepper>
      </Container>
      <ConfirmationModal
        title="Supprimer les modifications"
        open={cancelModalStatut}
        onConfirm={stepperCancel}
        onCancel={() => setCancelModalStatut(false)}
      >
        Êtes-vous sûr de vouloir supprimer ces modifications ? Cette action ne
        peut être annulée.
      </ConfirmationModal>
    </>
  );
};

export { Estimate };
