import { useState } from "react";

// Hook to Manage Maps object
export const useStateMap = (init) => {
  const [map, setStateMap] = useState(new Map(init));

  const set = (key, value) => {
    setStateMap(new Map(map.set(key, value)));
  };
  const get = (key) => {
    return map.get(key);
  };
  const remove = (key) => {
    setStateMap(new Map(map.delete(key)));
  };
  const clear = () => {
    setStateMap(new Map());
  };

  return [map, set, get, remove, clear];
};
