import React from 'react'
import { HiX, HiXCircle } from 'react-icons/hi'

export const Alert = ({ children, title, onDismiss }) => {
  return (
    <div className="rounded bg-red-200 flex justify-start text-red-800 tracking-wide mb-4 last:mb-0">
      <div className="flex-grow-0 p-4 text-red-600 text-lg">
        <HiXCircle />
      </div>
      <div className="flex-grow py-4 text-red-800 font-sans font-semibold text-xs ">
        <p>{title}</p>
        <div className="text-red-600 font-normal">
          {children}
        </div>
      </div>
      <div className="pt-2 pr-2">
        <button onClick={onDismiss} className="text-gray-500"><HiX /></button>
      </div>
    </div>
  )
}
