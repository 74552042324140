import React from "react";

export const SearchBar = ({ name, search, setSearch }) => {
  return (
    <input
      name={name}
      type="text"
      className="appearance-none w-full text-gray-700 border rounded py-3 px-4 leading-tight h-10"
      value={search}
      onChange={(evt) => setSearch(evt.target.value)}
      placeholder={`Recherche ` + name}
    />
  );
};
