import React from "react";
import { SelectButton } from "../buttons";
import { Card, CardContent } from "./Card";

function ContractCart({ title, description, onSelect, isSelected }) {
  return (
    <Card className="bg-blue-500 text-white">
      <CardContent className="flex flex-col">
        <h3 className="text-3xl font-semibold text-indigo-100">{title}</h3>
        <p className="flex-grow">{description}</p>
        <div className="flex flex-row justify-end mt-16">
          <SelectButton onSelect={onSelect} isSelected={isSelected}>
            Selectionner
          </SelectButton>
        </div>
      </CardContent>
    </Card>
  );
}

export { ContractCart };
