import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useStepperutils } from "./stepperUtils";
import { useValidSteps } from "./validSteps";

const useStepperList = (stepsList) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const {
    isStepMatchUrl,
    computeStepsList,
    stepMatchCurrentUrl,
  } = useStepperutils();
  const {
    validStep,
    invalidStep,
    checkValidStep,
    checkConsistence,
  } = useValidSteps();

  const validCurrentStep = () => {
    const step = steps[currentStep.stepIndex];
    validStep(step);
  };
  const inValidCurrentStep = () => {
    const step = steps[currentStep.stepIndex];
    invalidStep(step);
  };

  const isStepActive = (step) => {
    const isValid = checkValidStep(step);
    return (
      isStepMatchUrl(step) ||
      (currentStep.stepIndex > step.stepIndex && (isValid || step.isSkipable))
    );
  };
  const isStepDisabled = (step) => {
    let isDisabled = false;
    let prevStep = steps[step.stepIndex - 1];

    if (prevStep) {
      if (prevStep.isSkipable) {
        isDisabled = isStepDisabled(prevStep);
      } else {
        isDisabled = !checkValidStep(prevStep);
      }
    }
    return isDisabled;
  };

  const goToStep = (step) => {
    setCurrentStep(() => {
      if (step.stepIndex >= 0 && step.stepIndex < steps.length) {
        return step;
      }
    });
  };

  const previousStep = (step) => {
    if (steps && step && step.stepIndex > 0) {
      return steps[step.stepIndex - 1];
    }
    return null;
  };

  const maxValidStep = (newSteps) => {
    let searchIndex = newSteps.length - 1;
    while (!checkValidStep(newSteps[searchIndex])) {
      searchIndex--;
    }
    return newSteps[searchIndex];
  };

  const stepUpdate = (step) => {
    setSteps((steps) => {
      const newList = [...steps];
      newList[step.stepIndex] = step;
      return newList;
    });
    setCurrentStep((currentStep) => {
      return { ...steps[currentStep.stepIndex] };
    });
  };
  const stepsUpdate = (newSteps) => {
    checkConsistence(newSteps);
    setCurrentStep((currentStep) => {
      const currentIndex = currentStep.stepIndex;
      const stepsMaxIndex = newSteps.length - 1;
      if (currentIndex <= stepsMaxIndex) {
        return { ...newSteps[currentIndex] };
      } else {
        return maxValidStep(newSteps);
      }
    });
    setSteps(newSteps);
  };

  const allowGoToNext = () => {
    const hasNext = currentStep.stepIndex < steps.length - 1;
    const nextStep = steps[currentStep.stepIndex + 1];
    return hasNext && !isStepDisabled(nextStep);
  };
  const allowGoToPrev = () => {
    const hasPrev = currentStep.stepIndex > 0;
    return hasPrev;
  };

  const goNextStep = () => {
    handleNextStep && handleNextStep();
    setCurrentStep((currentStep) => {
      if (
        currentStep.stepIndex < steps.length - 1 &&
        !isStepDisabled(steps[currentStep.stepIndex + 1])
      ) {
        return steps[currentStep.stepIndex + 1];
      } else {
        return currentStep;
      }
    });
  };

  const goPrevStep = () => {
    handlePrevStep && handlePrevStep();
    setCurrentStep((currentStep) => {
      if (
        currentStep.stepIndex > 0 &&
        !isStepDisabled(steps[currentStep.stepIndex - 1])
      ) {
        return steps[currentStep.stepIndex - 1];
      } else {
        return currentStep;
      }
    });
  };

  let handlePrevStep;
  const setHandlePrevStep = (func) => {
    handlePrevStep = func;
  };
  let handleNextStep;
  const setHandleNextStep = (func) => {
    handleNextStep = func;
  };

  const isStepperValid = () => {
    const lastStep = steps[steps.length - 1];
    return (
      !steps.find((step) => !checkValidStep(step) && !step.isSkipable) &&
      isStepMatchUrl(lastStep)
    );
  };

  const [steps, setSteps] = useState(() => {
    const stepsListStored = window.sessionStorage.getItem("stepsList");
    let steps;

    if (stepsListStored) {
      steps = JSON.parse(stepsListStored);
    } else {
      steps = computeStepsList(stepsList);
    }

    return steps;
  });
  const [currentStep, setCurrentStep] = useState(() => {
    const stepMatch = stepMatchCurrentUrl(steps);
    const previous = previousStep(stepMatch);

    if (stepMatch && previous && checkValidStep(previous)) {
      return stepMatch;
    } else {
      return steps[0]
    }
  });

  useEffect(() => {
    const computedList = computeStepsList(stepsList);
    if (computedList.length !== steps.length) {
      stepsUpdate(computedList);
    }
  }, [stepsList]);

  useEffect(() => {
    if (currentStep && currentStep.stepUrl !== pathname) {
      push(currentStep.stepUrl);
    }
  }, [currentStep]);

  return {
    steps,
    validCurrentStep,
    inValidCurrentStep,
    currentStep,
    stepUpdate,
    isStepDisabled,
    isStepActive,
    goToStep,
    allowGoToNext,
    allowGoToPrev,
    goNextStep,
    goPrevStep,
    setHandlePrevStep,
    setHandleNextStep,
    isStepperValid,
  };
};

export { useStepperList };
