import React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';

const TextField = ({ name, isRequired = false, enabled = true, children, ...props }) => {
	return (
		<Field name={name}>
			{({ field, meta }) => {
				const classes = classNames(
					'appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight',
					{ 'border-red-500': meta.touched && meta.error }
				);
				return (
					<>
						<label
							htmlFor={field.name}
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
						>
							{children}
							{isRequired && <span className="text-red-600 ">*</span>}
						</label>
						<input
							disabled={!enabled}
							type="text"
							id={field.name}
							{...field}
							{...props}
							className={classes}
						/>
						{meta.touched && meta.error && (
							<p className="text-red-500 text-xs italic">{meta.error}</p>
						)}
					</>
				);
			}}
		</Field>
	);
};

export { TextField };
