import React from "react";
import { ProtectedRoute } from "../utils";
import { useStepperutils } from "./stepperUtils";

const Step = function ({ stepPath, children }) {
  const { pathToStep } = useStepperutils();
  return (
    <ProtectedRoute path={pathToStep(stepPath)}>{children}</ProtectedRoute>
  );
};

export { Step };
