import axios from "axios";
import download from "downloadjs";
import { fillPdf } from "./pdf-filler";
import Moment from "moment";

// Data
const _partAddress = (address, part) => {
  if (address.length > part) {
    const words = address.split(" ");
    let indexCut;
    let length = 0;
    words.every((item, i) => {
      length += item.length;
      if (part < length + i) {
        return false;
      }
      indexCut = i;
      return true;
    });
    const firstPart = words.slice(0, indexCut + 1).join(" ");
    const secondPart = words.slice(indexCut + 1).join(" ");
    return [firstPart, secondPart];
  }
  return [address, null];
};
const _removeHtmlPTag = (str) => {
  let _str = str.trim();
  if (_str.startsWith("<p>")) {
    _str = _str.substring(3);
  }
  if (_str.endsWith("</p>")) {
    _str = _str.substring(0, _str.length - 4);
  }
  return _str;
};

const _checkManagementprice = (prod, bypassInstallationFees, contractType) => {
  if (
    prod.activateFeeMultiplier ||
    ((prod.title.toLowerCase().includes("utilisateur") ||
      prod.title.toLowerCase().includes("tfcloud")) &&
      contractType.slug === process.env.REACT_APP_TFC_CLOUD_CONTRACT_NAME) ||
    prod.title.toLowerCase().includes("studio")
  ) {
    return !bypassInstallationFees
      ? Number(prod.management_price) * Number(prod.quantity)
      : 0;
  } else {
    return !bypassInstallationFees ? Number(prod.management_price) : 0;
  }
};

var reducPonctuel = {
  name: "",
  red: null,
};

var reducRecurent = {
  name: "",
  red: null,
};

const _createProductList = (estimate, bypassInstallationFees, contractType) => {
  const out = {};
  estimate.products.map((prod, ind) => {
    const _prod = Object.assign({}, prod);
    if (prod.name === "pourcentage_ponctuel") {
      let formatedReduc =
        prod.reduction.length === 1
          ? "0.0" + prod.reduction.replace(/\,|\./g, "")
          : "0." + prod.reduction.replace(/\,|\./g, "");
      reducPonctuel = {
        name: prod.name,
        red: (1 - Number(formatedReduc)).toFixed(3),
      };
    }

    if (prod.name === "pourcentage_recurent") {
      let formatedReduc =
        prod.reduction.length === 1
          ? "0.0" + prod.reduction.replace(/\,|\./g, "")
          : "0." + prod.reduction.replace(/\,|\./g, "");
      reducRecurent = {
        name: prod.name,
        red: Number(formatedReduc).toFixed(3),
      };
    }

    _prod.content = _removeHtmlPTag(_prod.content);
    _prod.total =
      _prod.name === "pourcentage_recurent"
        ? "- " +
          (_totalHTPrice(estimate) * reducRecurent.red).toFixed(2) +
          "€ HT"
        : _prod.name === "euro_recurent"
        ? "- " + _prod.reduction + "€ HT"
        : Number(_prod.price) * Number(_prod.quantity) + "€ HT";
    _prod.price =
      _prod.name === "pourcentage_recurent"
        ? "-" + _prod.reduction + "%"
        : (_prod.name === "euro_recurent"
            ? "-" + _prod.reduction
            : _prod.price) + "€ HT";
    _prod.management_price = !estimate.bypassInstallationFees
      ? _prod.name === "pourcentage_ponctuel"
        ? "- " + _prod.reduction + "%"
        : _prod.name === "euro_ponctuel"
        ? "- " + _prod.reduction + "€ HT"
        : _checkManagementprice(prod, bypassInstallationFees, contractType) +
          "€ HT"
      : 0 + "€ HT";

    for (const key of Object.keys(_prod)) {
      out[`${key}_${ind}`] = _prod[key].toString();
    }
  });
  return out;
};

const _totalHTPrice = (estimate) => {
  return estimate.products.reduce(
    (total, prod) => total + Number(prod.price) * Number(prod.quantity),
    0
  );
};

const _totalManagementPrice = (estimate) => {
  var tot = !estimate.bypassInstallationFees
    ? estimate.products.reduce(
        (total, prod) =>
          prod.name === "euro_ponctuel"
            ? total - Number(prod.reduction)
            : total +
              Number(_checkManagementprice(prod, false, estimate.contractType)),
        0
      )
    : "0";
  if (reducPonctuel.red !== null) {
    tot = tot * reducPonctuel.red;
  }
  return tot.toString();
};

const _formatData = (user, estimate, customer) => {
  const adressParts = _partAddress(customer.adress, 26);
  const orderAdressParts = _partAddress(customer.order_adress, 12);
  return {
    ...customer,
    adress_0: adressParts[0],
    adress_1: adressParts[1] || "",
    order_adress_0: orderAdressParts[0],
    order_adress_1: orderAdressParts[1] || "",
    sale_agent: `${customer.firstname} ${customer.lastname}`,
    ..._createProductList(
      estimate,
      estimate.bypassInstallationFees,
      estimate.contractType
    ),
    total: estimate.totalHT.toString(),
    total_ttc: estimate.totalTTC.toString(),
    total_management_price: _totalManagementPrice(estimate),
    saler_name: user && user.name,
    phone_rio: estimate.rio,
    phone_number: estimate.phoneNumber,
    phone_operator: estimate.operator,
    number_contract: customer.number ? customer.number.toString() : ""
  };
};

// Template
const _getPdfName = (contractTypeSlug, products) => {
  const prefixToSub = "contrat-";
  const type = contractTypeSlug.startsWith(prefixToSub)
    ? contractTypeSlug.substring(prefixToSub.length).replaceAll("-", "_")
    : contractTypeSlug.replaceAll("-", "_");

  if (products.length <= 10) {
    return `${type}`;
  } else {
    return `${type}_extented`;
  }
};
const _getPdfTemplate = async (templateName) => {
  const templateUrl = `${process.env.REACT_APP_API_URL}/pdf/templates/${templateName}`;
  return await axios
    .get(templateUrl, { responseType: "arraybuffer" })
    .then((res) => res.data);
};

// Pdf Filling And Download
const getFilledEstimatePdf = async (user, estimate, customer) => {
  const data = _formatData(user, estimate, customer);
  const name = _getPdfName(estimate.contractType.slug, estimate.products);
  return _getPdfTemplate(name).then((template) => {
    return fillPdf(template, data);
  });
};
const downloadPdf = (pdf, name) => {
  const fileName = name.replaceAll(" ", "_");
  const date = Moment().valueOf();
  download(pdf, `${fileName}_${date}.pdf`, "application/pdf");
};
const downloadFilledEstimatePdf = (user, estimate, customer) => {
  getFilledEstimatePdf(user, estimate, customer).then((pdf) => {
    downloadPdf(pdf, customer.company_name);
  });
};

export { getFilledEstimatePdf, downloadPdf, downloadFilledEstimatePdf };
