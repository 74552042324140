import React, { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { FiFile } from "react-icons/fi";
import { Button, SelectButton } from "../buttons";
import { Card, CardContent } from "./Card";

const ProductCart = ({
  item,
  onOpen,
  onAddToStore,
  onOpenProductFile,
  onSelect,
  selected,
}) => {
  // TODO Make this as clickInside hook---------------------------------
  const clickRef = useRef();
  const handleClick = (e) => {
    if (
      clickRef.current &&
      clickRef.current.contains(e.target) &&
      !(
        e.target.id === "productCard_addToCard" ||
        e.target.id === "productCard_select"
      )
    ) {
      onOpen();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);
  // -------------------------------------------------------------------

  return (
    <div
      className="flex max-w-full bg-white shadow rounded overflow-hidden cursor-pointer"
      ref={clickRef}
    >
      <div
        className="w-1/3 bg-contain bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${item.image})` }}
      ></div>
      <div className="w-2/3 p-4">
        <h1 className="text-gray-900 font-bold text-2xl">{item.title}</h1>
        <p className="mt-2 text-gray-600 text-sm">{item.summary}</p>
        <div className="flex item-center justify-between mt-3">
          <h1 className="text-gray-700 font-bold text-xl">{item.name.includes("pourcentage_") ? "-" + item.reduction + "%" : item.name.includes("euro_") ? "-" + item.reduction + "€" : item.price + "€"}</h1>
          {onAddToStore && (
            <Button
              id="productCard_addToCard"
              classes="font-bold uppercase rounded"
              onClick={() => onAddToStore(item)}
            >
              Ajouter au panier
            </Button>
          )}
          {onSelect && !onAddToStore && (
            <SelectButton
              id="productCard_select"
              onSelect={() => onSelect(item)}
              isSelected={selected && selected.id === item.id}
            >
              Selectionner{" "}
            </SelectButton>
          )}
        </div>
      </div>
    </div>
  );
};

export { ProductCart };
