import React, { createContext, useState } from 'react';
import Modal from '../components/Modal/Modal.js';
import { FiFile } from 'react-icons/fi';

let ModalContext;
const { Provider } = ModalContext = createContext()

const ProductModalContextProvider = function ({ children }) {

	const [stateModal, setStateModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);

    function openSelectedProduct({ item }) {
		setSelectedProduct(item);
		setStateModal(true);
    }
    
    function openNewFileTab(item) {
		window.open(item.product_file, '_blank');
	}

    return (
        <Provider value={{ stateModal, selectedProduct, openSelectedProduct, openNewFileTab }}>
            <Modal
                open={stateModal}
                title={'Fiche produit'}
                description={'Fiche info produit récapitulative'}
                actionText={'Ajouter au panier'}
                cancelActionText={'Fermer'}
                onCancelClick={() => setStateModal(false)}
                onClick={() => {setStateModal(false);}}
            >
                {selectedProduct && (
                    <div>
                        <img className="rounded-lg" src={selectedProduct.image} alt="" />
                        <div className="flex items-center justify-between mt-4">
                            <h4 className="text-xl font-bold text-blue-600">{selectedProduct.title}</h4>
                            <span className="font-bold text-xl bg-blue-500 text-white rounded-full px-2 py-1">{selectedProduct.price}€</span>
                        </div>

                        <PillAndText pillText={'Nom devis :'} value={selectedProduct.nom_de_produit_devis}></PillAndText>
                        <PillAndText pillText={'Frais de gestion par utilisateur :'} value={`${selectedProduct.user_management_price}€`}></PillAndText>
                        <PillAndText pillText={'Frais de mise en service :'} value={`${selectedProduct.management_price}€`}></PillAndText>

                        <div dangerouslySetInnerHTML={{ __html: selectedProduct.content, }} className="py-2 text-sm mt-4" ></div>
                        {selectedProduct.product_file && (
                            <div className="flex justify-end">
                                <button
                                    onClick={() => openNewFileTab(selectedProduct)}
                                    className="flex flex-row items-center justify-start rounded-full bg-blue-500 text-white px-4 py-2 font-semibold shadow-lg focus:outline-none focus:bg-blue-300 mt-4"
                                >
                                    <FiFile className="w-6 h-6 text-white mr-4" />
                                    <span className="uppercase font-semibold text-xs">Télécharger la fiche produit</span>
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </Modal>
            {children}
        </Provider>
    )
}

function PillAndText({ pillText, value }) {
	return (
		<div className="flex mt-2">
			<Pill text={pillText}></Pill>
			<h5 className="ml-2 font-semibold">{value}</h5>
		</div>
	);
}

function Pill({ text }) {
	return (
		<span className="text-xs uppercase font-medium rounded-full bg-gray-100 px-2 py-1 shadow">
			{text}
		</span>
	);
}

export { ProductModalContextProvider, ModalContext }