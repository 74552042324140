import React, { useEffect, useState } from "react";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { useEstimate } from "../../store/estimate/useEstimate";
import { Button } from "../buttons";
import { Card, CardContent } from "../carts";
import { calculateTotalPrice } from "../../utils/priceCalculations";

import useShoppingBasket from "../../store/Panier/Panier";

export default function ShoppingBasket({ handleValidate }) {
  const shoppingBasketStore = useShoppingBasket();
  const [totalHT, setTotalHT] = useState(0);
  const [totalTTC, setTotalTTC] = useState(0);
  const { contractType: contractType } = useEstimate();

  useEffect(() => {
    const { totalHT, totalTTC } = calculateTotalPrice({
      products: shoppingBasketStore.products,
      contractType,
    });

    setTotalHT(totalHT.toFixed(2));
    setTotalTTC(totalTTC.toFixed(2));
  }, [shoppingBasketStore.products, setTotalHT]);

  const validate = () => {
    handleValidate && handleValidate(totalHT, totalTTC);
  };

  return (
    <div className="w-full">
      <h2 className="text-2xl font-bold uppercase text-center text-white bg-blue-500 py-2 mb-10 rounded-full align-middle">
        Panier
      </h2>

      {!!totalHT && totalHT !== 0 && (
        <Card className="mb-8">
          <CardContent>
            <p className="self-center text-2xl mt-2 mb-6">
              <span>Total:</span>
              <span className="ml-2 font-bold">{totalHT} € (HT)</span>
            </p>
            <div className="flex justify-end">
              <Button onClick={validate}>Valider le panier</Button>
            </div>
          </CardContent>
        </Card>
      )}

      {shoppingBasketStore &&
        shoppingBasketStore.products.map((item) => (
          <Card key={item.id} className="shadow-none">
            <CardContent>
              <div className="flex">
                <div className="flex-grow">
                  <div className="flex items-center">
                    <img
                      className="rounded-full w-14"
                      src={item.thumbnail}
                      alt={item.thumbnail === "rabates" ? "" : item.title}
                    />
                    <div className="ml-4">
                      <h3 className="text-lg font-semibold">{item.title}</h3>
                      <span className="font-bold text-blue-600">
                        {item.name.includes("pourcentage_") ? "-" + item.reduction + " %" : item.name.includes("euro_") ? "-" + item.reduction + " €" : item.price + " €"}
                      </span>
                    </div>
                  </div>
                  <div className="mt-2">
                    <p className="text-sm">
                      Frais d'installation : {item.management_price} €
                    </p>
                    <p className="text-sm">
                      Fais de gestion par utilisateur:{" "}
                      {item.user_management_price} €
                    </p>
                  </div>
                </div>
                <div className="flex items-center flex-col">
                  <button
                    className="bg-blue-500 text-white p-2 rounded"
                    onClick={() => shoppingBasketStore.increaseQuantity(item)}
                  >
                    {" "}
                    <FiPlusCircle />{" "}
                  </button>
                  <p className="mt-2">{item.quantity}</p>
                  <button
                    className="bg-blue-300 text-white p-2 rounded mt-2"
                    onClick={() => shoppingBasketStore.decreaseQuantity(item)}
                  >
                    {" "}
                    <FiMinusCircle />{" "}
                  </button>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
    </div>
  );
}
