import React, { useState } from "react";
import { Formik, Form } from "formik";
import { string, object } from "yup";
import { TextField } from "../../components/utils";
import { StepperFormikConnect } from "../../components/stepper";
import { useEstimate } from "../../store/estimate/useEstimate";
import { useEffect } from "react";

const initialValues = {
  rio: "",
  phoneNumber: "",
};

const errorMessages = {
  rio: {
    required: "Le code RIO est obligatoire",
    validation: `Le code RIO n'est pas valide`,
    uppercase: "Le code RIO ne doit contenir que des majuscules",
  },
  phoneNumber: {
    required: "Le numéro de téléphone est obligatoire",
    validation: `Le numéro de téléphone n'est pas valide`,
  },
};

const validation = object({
  rio: string()
    .required(errorMessages.rio.required)
    .uppercase(errorMessages.rio.uppercase)
    .matches(
      /[A-Ea-e0-9][A-Za-z0-9][PE][A-Z0-9]{6}[A-Z0-9+]{3}/,
      errorMessages.rio.validation
    ),
  phoneNumber: string()
    .required(errorMessages.phoneNumber.required)
    .matches(
      /^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/,
      errorMessages.phoneNumber.validation
    ),
});

const ExistingNumber = () => {
  const { setRio, rio, setPhoneNumber, phoneNumber } = useEstimate();
  const [initial, setInitial] = useState(initialValues);

  useEffect(() => {
    if (rio && phoneNumber) {
      setInitial(Object.assign({}, initialValues, { rio, phoneNumber }))
    } else {
      setInitial(Object.assign({}, initialValues))
    }

  }, [rio, phoneNumber])

  const handleSubmit = ({ rio, phoneNumber }) => {
    setRio(rio);
    setPhoneNumber(phoneNumber);
  };

  return (
    <>
      <p className="text-lg">Entrez un numéro RIO ainsi que le numéro de téléphone associé</p>
      <Formik
        initialValues={initial}
        enableReinitialize={true}
        validationSchema={validation}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {() => (
          <Form className="w-full mt-16">
            <div className="flex mb-6">
              <div className="w-full">
                <TextField name="rio" isRequired={true}>
                  Numero RIO
                </TextField>
              </div>
            </div>
            <div className="flex mb-6">
              <div className="w-full">
                <TextField name="phoneNumber" isRequired={true}>
                  Numero de téléphone
                </TextField>
              </div>
            </div>

            <StepperFormikConnect />

          </Form>
        )}
      </Formik>
    </>
  );
};



export { ExistingNumber };
