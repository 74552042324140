/**
 * Card componant
 * version: 1.1.0
 * Author: Nicolas Liveris
 * Date: Octobre 2020
 */

import React from "react";
import classNames from 'classnames';

const Card = ({ children, className, ...args }) => {
  const _class = classNames(
    "max-w-full rounded overflow-hidden shadow-lg flex flex-col mb-4 bg-white",
    className
  )
  return <div className={_class} {...args} >{children}</div>
};

const CardContent = ({ children, className, ...args }) => {
  const _class = classNames(
    "px-6 py-4 flex-grow",
    className
  )
  return <div className={_class} {...args} >{children}</div>
};

const CardTitle = ({ children, className, ...args }) => {
  const _class = classNames(
    "font-bold text-xl mb-2",
    className
  )
  return <div className={_class} {...args} >{children}</div>
};

export { Card, CardTitle, CardContent };
