import { useLocation, useRouteMatch } from "react-router-dom";
import { flatFragmentsMap } from "../../utils/childrenFlatterner";

const useStepperutils = function () {
  const { url, path } = useRouteMatch();
  const { pathname } = useLocation();

  const pathToStep = (stepPath) => `${path}/${stepPath}`;
  const urlToStep = (stepPath) => `${url}/${stepPath}`;

  const computeItem = ({ name, stepPath, isSkipable }, i) => {
    const item = {
      name,
      stepIndex: i,
      stepUrl: urlToStep(stepPath),
      isSkipable,
    };
    return item;
  };
  const computeStepsList = (rawList) => {
    let steps;

    if (rawList && rawList !== []) {

      const filteredNullElem = rawList.filter((r) => r)
      const filterdActions = filterFooterAction(filteredNullElem)
      steps = flatFragmentsMap(filterdActions, (item, i) => computeItem(item.props, i));
    }
    return steps;
  };

  const extractFooterAction = (rawList) => {
    return rawList && rawList.find(item => item && item.props.__name === 'StepperFooterAction')
  }
  const filterFooterAction = (rawList) => {
    return rawList && rawList.filter(item => {
      return item && item.props.__name !== 'StepperFooterAction'
    })
  }

  const isStepMatchUrl = (step) => step.stepUrl === pathname;
  const stepMatchCurrentUrl = (steps) => {
    return Object.assign({}, steps.find(isStepMatchUrl));
  };

  return {
    pathToStep,
    urlToStep,
    computeStepsList,
    stepMatchCurrentUrl,
    isStepMatchUrl,
    filterFooterAction,
    extractFooterAction
  };
};

export { useStepperutils };
