import React, { useState } from "react";
import ContractReport from "./ContractReport";
import { Document, Page, pdfjs } from "react-pdf";
import { Pagination } from "../../components/pagination/Pagination";
import { useEffect } from "react";
import { Button } from "../../components/buttons";
import { useCustomer } from "../../store/customer/useCustomer";
import { Spinner } from "../../components/spinner/Spinner";
import { useEstimate } from "../../store/estimate/useEstimate";
import { useAuth0 } from "@auth0/auth0-react";
import { getFilledEstimatePdf, downloadPdf } from "../../utils/pdfProcess";

// Enable pdf.js use for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Summary = () => {
  const { user, isLoading } = useAuth0();

  const { data: customer } = useCustomer();
  const estimate = useEstimate();

  const [isLoaded, setIsLoaded] = useState(false);
  const [pdf, setPdf] = useState(null);

  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Displayed pdf management
  const displayPdf = (user, estimate, customer) => {
    getFilledEstimatePdf(user, estimate, customer).then((pdf) => {
      return setPdf({ data: pdf });
    });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsLoaded(true);
  };
  const previousPage = () => {
    if (currentPage > 1) setCurrentPage((currentPage) => currentPage - 1);
  };
  const nextPage = () => {
    if (currentPage < numPages)
      setCurrentPage((currentPage) => currentPage + 1);
  };
  const page = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (!isLoading && customer && estimate) {
      displayPdf(user, estimate, customer);
    }
  }, [isLoading, customer, estimate]);

  return (
    <>
      {customer && (
        <div className="grid grid-cols-2 gap-6">
          <div>
            <ContractReport />
          </div>
          <div>
            {!isLoaded && <Spinner />}
            {pdf && (
              <Document
                file={pdf}
                onLoadSuccess={onDocumentLoadSuccess}
                loading=""
              >
                <div className="py-3 flex items-center justify-between border-b border-gray-200">
                  <Pagination
                    pageNumber={numPages}
                    onPrev={previousPage}
                    onNext={nextPage}
                    onPage={page}
                  />
                  <Button
                    onClick={() => downloadPdf(pdf.data, customer.company_name)}
                  >
                    Télécharger
                  </Button>
                </div>
                <div className="flex justify-center">
                  <Page
                    pageNumber={currentPage}
                    width={window.innerWidth / 2 > 800 ? 800 : 600}
                  />
                </div>
              </Document>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export { Summary };
