import Axios from "axios";
import create from "zustand";
import { persist } from "zustand/middleware";

export const _storeName = "customers";
const _storage = sessionStorage;

export const _raw = {
  customers: [],
};

export const useCustomers = create(
  persist(
    (set, get) => ({
      ..._raw,

      getCustomers: async (options) => {
        const result = await Axios.get(
          `${process.env.REACT_APP_API_URL}/clients`,
          options
        );
        if (result.data) {
          set(() => ({
            customers: result.data.map((data) => ({
              id: data.id,
              ...data.data,
            })),
          }));
          return result.data;
        }
        return null;
      },

      /**
       * Reset store has defaul data and delete persisted
       */
      reset: () => {
        set(() => ({ ..._raw }));
      },
    }),
    {
      name: _storeName,
      storage: _storage,
    }
  )
);
