import React from "react";
import classNames from "classnames";
import { FiFileText } from "react-icons/fi";

const StepHeaderElem = ({ name, isEdge, isActive, isDisabled, goTo }) => {
  const activeColor = "blue-500";
  const inactiveColor = "blue-300";

  const labelClass = classNames(
    `rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 flex justify-center items-center`,
    {
      [`border-${activeColor} text-white bg-${activeColor}`]: isActive,
      [`border-${inactiveColor} text-${inactiveColor}`]: !isActive,
      "opacity-50 cursor-not-allowed": isDisabled,
      [`cursor-pointer hover:bg-${activeColor}`]: !isDisabled,
    }
  );
  const textClass = classNames(
    "absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase",
    {
      [`text-${activeColor}`]: isActive,
      [`text-${inactiveColor}`]: !isActive,
      "opacity-50": isDisabled,
    }
  );
  const pathClass = classNames(
    "flex-auto border-t-2 transition duration-500 ease-in-out",
    {
      [`border-${activeColor}`]: isActive,
      [`border-${inactiveColor}`]: !isActive,
    }
  );

  return (
    <>
      {isEdge && <div className={pathClass}></div>}
      <div className="flex items-center relative">
        <div
          onClick={() => {
            !isDisabled && goTo();
          }}
          className={labelClass}
        >
          <FiFileText />
        </div>
        <div className={textClass}>{name}</div>
      </div>
    </>
  );
};

export { StepHeaderElem };
