import React from "react";

const Overlay = () => {
  return (
    <div className="fixed inset-0 transition-opacity">
      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
  );
};

export { Overlay };
