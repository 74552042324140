import React from "react";
import { Link, useHistory } from "react-router-dom";
import { FiPower } from "react-icons/fi";
import { useAuth0 } from "@auth0/auth0-react";
import Logo from "../../logo.png";

import { useValidSteps } from "../stepper";
import useShoppingBasket from "../../store/Panier/Panier";
import { useEstimate } from "../../store/estimate/useEstimate";
import { useCustomer } from "../../store/customer/useCustomer";

export default function Menu() {
  const { push } = useHistory();
  const { resetValidsSteps } = useValidSteps();
  const { logout } = useAuth0();

  const { reset: resetEstimate } =  useEstimate();
  const { reset: resetCustomer } = useCustomer();
  const { reset: resetPanier } = useShoppingBasket();

  function handleNewDevis() {
    resetCustomer();
    resetEstimate();
    resetPanier();
    resetValidsSteps();

    push("/estimate");
  }

  function disconnect() {
    resetCustomer();
    resetEstimate();
    resetPanier();
    resetValidsSteps();
    logout({ returnTo: window.location.origin });
  }

  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/">
              <div className="flex-shrink-0">
                <img className="h-10 rounded" src={Logo} alt="Workflow logo" />
              </div>
            </Link>
            <div>
              <div className="ml-10 flex items-baseline space-x-4">
                <Link
                  to="/estimate-manager"
                  className="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  Devis
                </Link>
              </div>
            </div>
            <div>
              <div className="ml-10 flex items-baseline space-x-4">
                <Link
                  to="/customers"
                  className="px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  Clients
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="ml-4 flex items-center md:ml-6">
              <button
                className="px-3 py-2 rounded-md text-sm font-medium text-white bg-blue-500 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                onClick={handleNewDevis}
              >
                Créer un devis
              </button>
              <button
                className="ml-4 px-3 py-3 rounded-md text-sm font-medium text-white bg-red-500 hover:text-white hover:bg-red-700 focus:outline-none focus:text-white focus:bg-red-700"
                onClick={disconnect}
              >
                <FiPower />
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

/*
<Link
  to="/estimate"
  className="mr-4 px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
>
  Continuer le devis
</Link>
*/
