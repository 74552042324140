import { PDFDocument, StandardFonts, rgb } from "pdf-lib";

const fillTextField = (form, fieldName, value, enableReadOnly) => {
  const field = form.getTextField(fieldName)
  if (field) {
    field.setText(value)
    if (enableReadOnly) field.enableReadOnly()
    return field
  }
  throw Error ('Invalid field')
}

const fillFields = (form, object) => {
  for (const prop of Object.keys(object)) {
    try{
      fillTextField(form, prop, object[prop], true)
    }
    catch {}
  }
}

export async function fillPdf(template, data) {
  try {
    if (!data || !template) throw Error('template and data must be provided')

    // Load a PDF with form fields
    const document = await PDFDocument.load(template);

    // Get the form containing all the fields
    const form = document.getForm();

    // Fill th form
    fillFields(form, data)

    // Write file
    return await document.save({ updateFieldAppearances: true });
    
  } catch (error) {
    if (error) throw error
  }
}
