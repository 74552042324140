import { Children, Fragment } from "react";

const _fragmentsMap = (items) => {
  return Children.map(items, (item) => {
    if (item) {
      if (item.type === Fragment) {
        return _fragmentsMap(item.props.children);
      } else {
        return item;
      }
    }
  });
};

const flatFrangmentsForEach = (items, func) => {
  return _fragmentsMap(items).forEach(func);
};

const flatFragmentsMap = (items, func) => {
  return _fragmentsMap(items).map(func);
};

export { flatFragmentsMap, flatFrangmentsForEach };
