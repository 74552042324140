import React from "react";
import { Alert } from "./Alert";
import { SUCCESS_ALERT } from "./AlertContext";
import { SuccessAlert } from "./SuccessAlert";

export const AlertStack = ({ alerts, onDismiss }) => {
  return (
    <div className="fixed top-20 right-0 w-3/12 pr-4">
      {alerts &&
        alerts.map((alert, index) => {
          if (alert.type === SUCCESS_ALERT) {
            return (
              <SuccessAlert
                key={alert.id}
                title={alert.title}
                onDismiss={() => onDismiss(alert)}
              >
                {alert.info ? alert.info : null}
              </SuccessAlert>
            );
          }

          return (
            <Alert
              key={alert.id}
              title={alert.title}
              onDismiss={() => onDismiss(alert)}
            >
              {alert.info ? alert.info : null}
            </Alert>
          );
        })}
    </div>
  );
};
