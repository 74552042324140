import React from 'react'

/**
 * Paging React component
 *
 * Handle the paging of the front page
 */
function Paging({ updatePaging, paging, maxPages }) {
  return (
    <div className="flex justify-between mt-6">
      <div>
        {paging > 0 && (
          <button
            className="px-3 py-2 rounded-md text-sm font-medium text-white bg-blue-500 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            onClick={() => updatePaging(paging - 1)}
          >
            Précédent
          </button>
        )}
      </div>
      <p className="shadow-md px-3 py-2 rounded-md text-sm font-medium">
        <span className="font-bold text-blue-500">{paging + 1}</span> /{" "}
        {maxPages + 1}
      </p>
      <div>
        {paging < maxPages && (
          <button
            className="px-3 py-2 rounded-md text-sm font-medium text-white bg-blue-500 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            onClick={() => updatePaging(paging + 1)}
          >
            Suivant
          </button>
        )}
      </div>
    </div>
  );
}

export { Paging };
