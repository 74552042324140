import React from "react";
import { ModalContainer, ModalContent, Overlay } from ".";

const ModalBox = ({ children, overlay = true, open }) => {
  return (
    <>
      {open && (
        <ModalContainer>
          {overlay && <Overlay />}
          <ModalContent>{children}</ModalContent>
        </ModalContainer>
      )}
    </>
  );
};

export { ModalBox };
