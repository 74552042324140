import React, { useRef, useEffect } from 'react';
import { FiFile } from 'react-icons/fi';

export default function Modal({
	open,
	title,
	children,
	description,
	actionText,
	cancelActionText,
	onCancelClick,
	onClick,
}) {
	const wrapperRef = useRef(null);
	const focusRef = useRef(null);
	useOutsideAlerter(wrapperRef, onCancelClick);

	useEffect(() => {
		if (open) focusRef.current.focus();
	}, [open]);

	return (
		<React.Fragment>
			{open && (
				<div className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-20">
					<div className="fixed inset-0 transition-opacity">
						<div className="absolute inset-0 bg-gray-500 opacity-75"></div>
					</div>

					<div
						ref={wrapperRef}
						className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full z-20"
					>
						<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
							<div className="sm:flex sm:items-start">
								<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
									<FiFile className="h-4 w-4 text-blue-600" />
								</div>
								<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										{title}
									</h3>
									<div className="mt-2">
										<p className="text-sm leading-5 text-gray-500">
											{description}
										</p>
									</div>
									<div className="mt-2">{children}</div>
								</div>
							</div>
						</div>
						<div className="bg-gray-50 p-4 sm:px-6 sm:py-4 sm:flex sm:flex-row sm:justify-end">
							<span className="flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
								<button
									type="button"
									className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
									onClick={onCancelClick}
									ref={focusRef}
								>
									{cancelActionText}
								</button>
							</span>
							<span className="flex w-full rounded-md shadow-sm mt-3 sm:ml-3 sm:w-auto sm:mt-0">
								<button
									type="button"
									className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-blue-700 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
									onClick={onClick}
								>
									{actionText}
								</button>
							</span>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
}

function useOutsideAlerter(ref, fn) {
	/**
	 * Alert if clicked on outside of element
	 */
	function handleClickOutside(event) {
		if (ref.current && !ref.current.contains(event.target)) {
			fn();
		}
	}

	useEffect(() => {
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});
}
