export function getUserRole({ user }) {
  if (!user) return roles.USER;
  return user["https://hasura.io/jwt/claims"]["x-hasura-default-role"];
}

export const roles = {
  ADMIN: "admin",
  EXTERNAL: "external",
  USER: "user",
};
