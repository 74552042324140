import { useAuth0 } from "@auth0/auth0-react";

const DISPLAY_TOKEN = true;

const useAuthHeader = () => {
  const { getIdTokenClaims, isLoading, isAuthenticated } = useAuth0();

  async function getAuthHeader() {

    const { __raw } = await getIdTokenClaims()

    console.log('__raw', __raw)
    // TODO REMOVE BEFORE PROD
    if (__raw) {
      if (DISPLAY_TOKEN) {
        console.warn(__raw);
      }
      return { authorization: `Bearer ${__raw}` };
    }
    return null
  }

  return { getAuthHeader, isLoading, isAuthenticated };
};

export { useAuthHeader };
