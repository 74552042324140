import React from 'react';
import classNames from 'classnames';
import { ProductCart } from '../carts';

export default function ProductGrid({
	products,
	openSelectedProduct,
	onAddToCard,
	onOpenFile,
	onSelectProduct,
	selectedProduct,
	colNumber = 3,
	className,
	...args
}) {
	const _class = classNames(
		`w-full grid grid-cols-${colNumber} gap-8 items-start`,
		className
	)
	return (
		<div className={_class} {...args}>
			{products && products.map((item) => {
				return (
					<ProductCart
						key={item.id}
						item={item}
						onOpen={() => openSelectedProduct({ item })}
						onAddToStore={onAddToCard}
						onOpenProductFile={onOpenFile}
						onSelect={onSelectProduct}
						selected={selectedProduct}
					/>
				);
			})}
		</div>
	);
}
