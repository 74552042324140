import React from "react";

import "./css/tailwind-build.css";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Login, Estimate, EstimateManager, Customers } from "./pages";
import { Auth0Provider } from "@auth0/auth0-react";
import { ProtectedRoute } from "./components/utils";
import { AlertStackContext } from "./components/alerts";

import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

function App() {
  /**
   * Do not switch estimate to ProtectedRoute, or it will fail at contract step
   */
  return (
    <AlertStackContext>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
      >
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/customers" component={Customers} />
            <Route path="/estimate" component={Estimate} />
            <Route path="/estimate-manager" component={EstimateManager}></Route>

            <ProtectedRoute path="/">
              <EstimateManager />
            </ProtectedRoute>
          </Switch>
        </Router>
      </Auth0Provider>
    </AlertStackContext>
  );
}

export default App;