import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import ProductGrid from "../../components/ProductGrid/ProductGrid";
import { ModalContext } from "../../contexts/productModalContext";
import { StepsContext } from "../../components/stepper/Stepper";
import { useEstimate } from "../../store/estimate/useEstimate";
import { useAuthHeader } from "../../utils/auth/useAuthHeader";
import { AlertContext } from "../../components/alerts";
import { Spinner } from "../../components/spinner/Spinner";

const Offers = () => {
  const { validCurrentStep, inValidCurrentStep } = useContext(StepsContext);
  const { openSelectedProduct } = useContext(ModalContext);

  const { getAuthHeader, isLoading } = useAuthHeader();
  const { products, setProducts, contractType, setTotalHT, setTotalTTC } = useEstimate();

  const [mobilesProducts, setMobilesProducts] = useState(null);
  const [selectedMobileProducts, setSelectedMobileProducts] = useState(products[0]);
  const [loading, setLoading] = useState(false);
  const alertManager = useContext(AlertContext);

  const loadProducts = () => {
    async function getMobileProducts(otpions) {
      return await axios.get(`${process.env.REACT_APP_API_URL}/wp-proxy/products/categories/${contractType.id}`, otpions);
    }
    return getAuthHeader()
      .then((authHeader) => {
        const options = { headers: authHeader }
        return getMobileProducts(options)
      })
  }

  useEffect(() => {
    if (selectedMobileProducts) {
      const totalHT =
        Number(selectedMobileProducts.price) +
        Number(selectedMobileProducts.user_management_price)
      const totalTTC = totalHT + totalHT * 0.2;

      setTotalHT(totalHT.toFixed(2));
      setTotalTTC(totalTTC.toFixed(2));
      setProducts([selectedMobileProducts]);
      setProducts([{ ...selectedMobileProducts, quantity: 1 }]);

      validCurrentStep();
    } else {
      inValidCurrentStep();
    }
  }, [selectedMobileProducts]);

  useEffect(() => {
    if (!isLoading && contractType) {
      setLoading(true)
      loadProducts()
        .then(products => {
          setMobilesProducts(products.data)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          alertManager.riseAlert({
            title: "Oups ! Une erreur c'est produite",
            info:
              "Une erreur lors de la récuparation des données c'est produite. Rafraichir la page pour ré-éssayer (ctrl/cmd + r).",
          });
        });
    }
  }, [isLoading, contractType]);

  const selectProduct = (item) => {
    setSelectedMobileProducts(item);
  };

  return (
    <>
      {loading && <Spinner />}
      {
        !loading && products && <ProductGrid
          products={mobilesProducts}
          openSelectedProduct={openSelectedProduct}
          onSelectProduct={selectProduct}
          selectedProduct={selectedMobileProducts}
        />
      }

    </>
  );
};

export { Offers };
