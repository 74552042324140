import create from "zustand";
import compose from "lodash/flow";

const useShoppingBasket = create((set) => ({
  products: [],
  addProduct: (product, quantity) =>
    set((state) => ({
      products: addQuantityIfAlreadyExists(
        productFactory(product, quantity),
        state.products
      ),
    })),
  removeProduct: (product) =>
    set((state) => ({ products: removeFromId(product, state.products) })),
  increaseQuantity: (product, quantity) =>
    set((state) => ({ products: increase(product, quantity, state.products) })),
  decreaseQuantity: (product, quantity) =>
    set((state) => ({
      products: compose([decrease, deleteQuantity0])(
        product,
        quantity,
        state.products
      ),
    })),
  setQuantity: (product, quantity) =>
    set((state) => ({
      products: setQuantity(product, quantity, state.products),
    })),
  bulk: (products) => set((state) => ({ products })),
  reset: () => set((state) => ({ products: [] })),
}));

export default useShoppingBasket;

/***
 * Implementation details
 */

/**
 * Creates a standardized Product Object
 * @param {Object} product
 * @param {Number} quantity
 */
function productFactory(product, quantity) {
  return Object.assign({}, product, { quantity: quantity || 1 });
}

/**
 * Check if a product doesn't already exists and add quantity
 * @param {Object} product
 * @param {Array} state
 */
function addQuantityIfAlreadyExists(product, state) {
  let alreadyExists = false;
  const newState = state.map((item) => {
    if (item.id === product.id) {
      alreadyExists = true;
      return Object.assign(item, {
        quantity: item.quantity + product.quantity,
      });
    }

    return item;
  });

  if (alreadyExists === false) {
    return [...newState, product];
  }

  return newState;
}

/**
 * Remove an object based on item passed onto it
 * @param {Object} item
 * @param {Array} state
 */
function removeFromId(item, state) {
  return state.filter((element) => element.id !== item.id);
}

/**
 * Increase the quantity of the item passed onto it by one or the defined quantity
 * @param {Object} item
 * @param {Number} quantity
 * @param {Array} state
 */
function increase(item, quantity, state) {
  return state.map((element) => {
    if (element.id === item.id) {
      return Object.assign(element, {
        quantity:
          quantity === undefined
            ? element.quantity + 1
            : (element.quantity += quantity),
      });
    }

    return element;
  });
}

/**
 * Decrease the quantity of the item passed onto it by one or the defined quantity
 * @param {Object} item
 * @param {Number} quantity
 * @param {Array} state
 */
function decrease(item, quantity, state) {
  return state.map((element) => {
    if (element.id === item.id) {
      return Object.assign(element, {
        quantity:
          quantity === undefined
            ? element.quantity - 1
            : (element.quantity -= quantity),
      });
    }

    return element;
  });
}

/**
 * Delete state items that have quantity 0
 * @param {Array} state
 */
function deleteQuantity0(state) {
  return state.filter((element) => element.quantity > 0);
}

/**
 * Set the quantity of the item passed onto it
 * @param {Object} item
 * @param {Number} quantity
 * @param {Array} state
 */
function setQuantity(item, quantity, state) {
  return state.map((element) => {
    if (element.id === item.id) {
      return Object.assign(element, {
        quantity,
      });
    }

    return element;
  });
}

export {
  addQuantityIfAlreadyExists,
  productFactory,
  removeFromId,
  increase,
  decrease,
  deleteQuantity0,
  setQuantity,
};
