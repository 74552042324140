/**
 * SelectButton componant
 * version: 1.0.1
 * Author: Nicolas Liveris
 * Date: Octobre 2020
 */

import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import { Button } from "./Button";

const SelectButton = ({ onSelect, children, isSelected }) => {
  return (
    <Button
      onClick={onSelect}
      color={isSelected && "blue-900"}
      hoverColor="blue-900"
      focusColor="blue-900"
    >
      <FiCheckCircle className="inline-flex w-4 h-4 mr-2" />
      <span className="inline-flex">{children}</span>
    </Button>
  );
};

export { SelectButton };
