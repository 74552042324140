import axios from "axios";
import download from "downloadjs";
import Fuse from "fuse.js";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AlertContext, ERROR_ALERT, SUCCESS_ALERT } from "../components/alerts";
import { Button } from "../components/buttons";
import Menu from "../components/Menu/Menu";
import { Paging } from "../components/paging/paging";
import { SearchBar } from "../components/utils";
import Container from "../components/utils/Container";
import { useCustomers } from "../store/customer/useCustomers";
import { useAuthHeader } from "../utils/auth/useAuthHeader";
import { Spinner } from "../components/spinner/Spinner";

export const Customers = () => {
  const { getAuthHeader, isAuthenticated } = useAuthHeader();
  const alertManager = useContext(AlertContext);
  const { customers, getCustomers } = useCustomers();
  const [search, setSearch] = useState("");
  const [paging, setPaging] = useState(0);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [importFiles, setImportFiles] = useState();
  const [loading, setLoading] = useState(false);

  const estimatePerPage = 15;

  const fuse = useRef();

  const fetchCustomers = async () => {
    try {
      const authHeader = await getAuthHeader();
      const options = { headers: authHeader };
      await getCustomers(options);
    } catch (error) {
      alertManager.riseAlert({
        title: "Oups ! Une erreur c'est produite",
        info: "Une erreur lors de la récuparation des données c'est produite. Rafraichir la page pour ré-éssayer (ctrl/cmd + r).",
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (!isAuthenticated) return;
      await fetchCustomers();
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isEmpty(customers)) {
      fuse.current = new Fuse(customers, {
        keys: ["company_name", "siret", "telephone", "email", "firstname"],
        threshold: 0.1,
      });
    }
  }, [customers]);

  useEffect(() => {
    if (!isEmpty(search)) {
      const res = fuse.current.search(search.trim().toLowerCase());
      setFilteredCustomers(res.map((r) => r.item));
    } else {
      setFilteredCustomers(customers);
    }
  }, [search, customers]);

  const updatePaging = (number) => {
    if (
      number > Math.floor(filteredCustomers.length / estimatePerPage) ||
      number < 0
    )
      return;
    setPaging(number);
  };

  const exportCustomers = async () => {
    const authHeader = await getAuthHeader();
    const options = { headers: authHeader, responseType: "blob" };
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/clients/export`,
      options
    );

    if (result.data) {
      download(result.data, "customer.xlsx", "application/octet-stream");
    }
  };

  const importCustomers = async () => {
    try {
      setLoading(true);
      const authHeader = await getAuthHeader();
      const options = {
        headers: { ...authHeader, "Content-Type": "multipart/form-data" },
      };

      var formData = new FormData();
      formData.append("file", importFiles[0]);

      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/clients/import`,
        formData,
        options
      );

      if (result) {
        fetchCustomers();
        alertManager.riseAlert({
          title: "Import terminé",
          info: "L'import des clients s'est terminé avec succès.",
          type: SUCCESS_ALERT,
        });
      }
    } catch (error) {
      alertManager.riseAlert({
        title: "Erreur d'inport",
        info: "L'import des clients à échoué",
        type: ERROR_ALERT,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Menu></Menu>
      {loading && <Spinner />}
      {!loading && (
        <Container className="flex-grow">
          <Container className="flex justify-between mt-8">
            <div>
              <input
                type="file"
                id="customer-inputs"
                name="customer-inputs"
                onChange={(e) => setImportFiles(e.target.files)}
              />
              <Button onClick={() => importCustomers()} disabled={!importFiles}>
                Import
              </Button>
            </div>
            <Button onClick={() => exportCustomers()}>Export</Button>
          </Container>
          <div className="flex flex-col mx-auto w-11/12 mt-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 sm:px-6 lg:px-8">
                <SearchBar
                  name="clients"
                  search={search}
                  setSearch={setSearch}
                />
              </div>
              {!isEmpty(filteredCustomers) && (
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="table-auto w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Id
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Entreprise
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Nom
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Prénom
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Téléphone
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fonction
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Siret
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Numéro client
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredCustomers
                          .slice(
                            estimatePerPage * paging,
                            estimatePerPage * paging + estimatePerPage
                          )
                          .map((customer) => (
                            <CustomerRow
                              key={customer.id}
                              customer={customer}
                            />
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Paging
                    updatePaging={updatePaging}
                    paging={paging}
                    maxPages={Math.floor(
                      filteredCustomers.length / estimatePerPage
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export const CustomerRow = ({ customer }) => {
  if (!customer) return null;

  return (
    <tr>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {customer.id}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {customer.company_name}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {customer.firstname}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {customer.lastname}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {customer.telephone}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {customer.function}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {customer.siret}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {customer.number}
      </td>
    </tr>
  );
};
