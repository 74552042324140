import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import Autocomplete from "react-autocomplete";
import { bool, object, string } from "yup";
import { AlertContext } from "../../components/alerts";
import { StepperFormikConnect } from "../../components/stepper";
import { TextField } from "../../components/utils";
import { useCustomer } from "../../store/customer/useCustomer";
import { useCustomers } from "../../store/customer/useCustomers";
import { useAuthHeader } from "../../utils/auth/useAuthHeader";

const initialValues = {
  company_name: "",
  legal_form: "",
  siret: "",
  siret_on_process: false,
  adress: "",
  ape_code: "",
  order_adress: "",
  firstname: "",
  lastname: "",
  function: "",
  telephone: "",
  email: "",
  fax: "",
  order_contact: "",
  number: "",
};

const errorMessages = {
  required: "Ce champ est obligatoire",
  siret_format: "Numéro de Siret invalide",
  ape_format: "Code APE invalide",
  number_format: "Le numero client n'est composé que de nombres",
  phone_format:
    "Le numéro de téléphone doit être renseingé comme ceci : 0610112345",
};

const customerSchema = object({
  firstname: string().required(errorMessages.required),
  lastname: string().required(errorMessages.required),
  adress: string().required(errorMessages.required),
  order_adress: string().required(errorMessages.required),
  order_contact: string(),

  siret_on_process: bool(),
  siret: string().matches(/[0-9]{14}/, errorMessages.siret_format),

  ape_code: string().matches(/[0-9]{10}/, errorMessages.ape_format),
  function: string(),
  email: string().email().required(errorMessages.required),
  telephone: string()
    .matches(/[0-9]{10}/, errorMessages.phone_format)
    .required(errorMessages.required),
  fax: string(),
  company_name: string().required(errorMessages.required),
  legal_form: String(),
  number: string()
    .nullable()
    .matches(/[0-9]*/, errorMessages.number_format),
});

const CustomerInfo = () => {
  const { setData: setCustomerData, data: customerData, setId } = useCustomer();

  const [initial, setInitial] = useState(initialValues);

  const alertManager = useContext(AlertContext);
  const { getAuthHeader, isAuthenticated } = useAuthHeader();
  const { customers, getCustomers } = useCustomers();

  useEffect(() => {
    setInitial(Object.assign({}, initialValues, customerData));
  }, [customerData]);

  useEffect(() => {
    (async () => {
      if (!isAuthenticated) return;
      try {
        const authHeader = await getAuthHeader();
        const options = { headers: authHeader };
        await getCustomers(options);
      } catch (error) {
        alertManager.riseAlert({
          title: "Oups ! Une erreur c'est produite",
          info: "Une erreur lors de la récuparation des données c'est produite. Rafraichir la page pour ré-éssayer (ctrl/cmd + r).",
        });
      }
    })();
  }, [isAuthenticated]);

  const handleSubmit = (values) => {
    const out = {};

    for (const field in values) {
      if (Object.hasOwnProperty.call(values, field)) {
        const value = values[field];
        // trim the value and asign it
        if (typeof value === "string") {
          out[field] = value.trim();
        } else {
          out[field] = value;
        }
      }
    }

    setCustomerData(out);
  };

  return (
    <>
      <div className="mb-8">
        <h1 className="text-2xlfont-bold uppercase text-center text-white bg-blue-500 py-2 mb-4 rounded-full align-middle">
          Information client
        </h1>
      </div>
      <Formik
        initialValues={initial}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={customerSchema}
        validateOnMount={true}
      >
        {(props) => (
          <Form className="w-full">
            <div className="flex -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <Autocomplete
                  wrapperProps={{ className: "w-full" }}
                  renderInput={(props) => (
                    <>
                      <label
                        htmlFor="company_name"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Raison sociale
                        <span className="text-red-600 ">*</span>
                      </label>
                      <input
                        name="company_name"
                        autoComplete="new-password"
                        className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight"
                        {...props}
                      />
                    </>
                  )}
                  getItemValue={(item) => item.company_name}
                  items={customers}
                  shouldItemRender={(item, value) => {
                    return (
                      item.company_name
                        .toLowerCase()
                        .indexOf(value.toLowerCase()) > -1
                    );
                  }}
                  renderItem={(item, isHighlighted) => (
                    <div
                      key={item.id}
                      className="px-4"
                      style={{
                        background: isHighlighted ? "lightgray" : "white",
                      }}
                    >
                      <div className="flex flex-col p-4">
                        {item.company_name}
                        <span className="text-gray-600">{item.adress}</span>
                      </div>
                    </div>
                  )}
                  value={props.values.company_name}
                  onChange={(e) => {
                    props.setFieldValue("company_name", e.target.value);
                  }}
                  onSelect={(val, item) => {
                    setId(item.id);
                    props.setFieldValue("company_name", val);
                    props.setFieldValue("adress", item.adress);
                    props.setFieldValue("ape_code", item.ape_code);
                    props.setFieldValue("email", item.email);
                    props.setFieldValue("fax", item.fax);
                    props.setFieldValue("firstname", item.firstname);
                    props.setFieldValue("function", item.function);
                    props.setFieldValue("siret", item.siret);
                    props.setFieldValue("lastname", item.lastname);
                    props.setFieldValue("legal_form", item.legal_form);
                    props.setFieldValue(
                      "order_adress",
                      item.order_adress === "" ? item.adress : item.order_adress
                    );
                    props.setFieldValue("order_contact", item.order_contact);
                    props.setFieldValue(
                      "siret_on_process",
                      item.siret_on_process
                    );
                    props.setFieldValue("telephone", item.telephone);
                    props.setFieldValue(
                      "number",
                      item.number ? item.number.toString() : ""
                    );
                  }}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <TextField name="legal_form">Forme Juridique</TextField>
              </div>
            </div>
            <div className="flex -mx-3 mb-6">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <TextField
                  name="siret"
                  enabled={!props.values.siret_on_process}
                >
                  Siret
                </TextField>
                <label
                  htmlFor="siret_on_process"
                  className="md:w-2/3 block text-gray-500 font-bold"
                >
                  <Field
                    type="checkbox"
                    name="siret_on_process"
                    id="siret_on_process"
                    className="mr-2 leading-tight"
                  />
                  Siret en cours de création
                </label>
              </div>
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <TextField name="ape_code">Code APE</TextField>
              </div>
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <TextField name="number">Numéro client</TextField>
              </div>
            </div>
            <div className="flex -mx-3 mb-6">
              <div className="w-full px-3">
                <TextField name="adress" isRequired={true}>
                  Adresse
                </TextField>
              </div>
            </div>

            <div className="flex -mx-3 mb-6">
              <div className="w-full px-3">
                <TextField name="order_adress" isRequired={true}>
                  Adresse de facturation
                </TextField>
              </div>
            </div>
            <div className="flex -mx-3 mb-6">
              <div className="w-full px-3">
                <TextField name="order_contact">
                  Contact de facturation
                </TextField>
              </div>
            </div>
            <div className="flex -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <TextField name="firstname" isRequired={true}>
                  Prénom représentant
                </TextField>
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <TextField name="lastname" isRequired={true}>
                  Nom représentant
                </TextField>
              </div>
            </div>

            <div className="flex -mx-3 mb-6">
              <div className="w-full px-3">
                <TextField name="function">Function</TextField>
              </div>
            </div>

            <div className="flex -mx-3 mb-6">
              <div className="w-full px-3">
                <TextField name="email" isRequired={true}>
                  Email
                </TextField>
              </div>
            </div>

            <div className="flex -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <TextField name="telephone" isRequired={true}>
                  Téléphone
                </TextField>
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <TextField name="fax">Fax</TextField>
              </div>
            </div>

            <StepperFormikConnect />
          </Form>
        )}
      </Formik>
    </>
  );
};

export { CustomerInfo, initialValues as customerInitialValues };
