import React, { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const Pagination = ({ pageNumber, onPrev, onNext, onPage }) => {
  const createMap = (nb) => {
    let arr = [];
    for (let index = 1; index <= nb; index++) {
      arr.push(index);
    }
    return arr;
  };

  return (
    <div className="flex-1 flex items-center justify-start">
      <div>
        <nav className="relative z-0 inline-flex shadow-sm">
          <button
            onClick={onPrev}
            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
            aria-label="Previous"
          >
            <FiChevronLeft />
          </button>
          {pageNumber &&
            createMap(pageNumber).map((page) => (
              <button
                key={page}
                onClick={() => onPage(page)}
                className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
              >
                {page}
              </button>
            ))
          }
          <button
            onClick={onNext}
            className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
            aria-label="Next"
          >
            <FiChevronRight />
          </button>
        </nav>
      </div>
    </div>
  );
};

export { Pagination };
