import React, { useContext, useEffect, useState } from "react";
import { ContractCart } from "../../components/carts";
import { StepsContext } from "../../components/stepper/Stepper";
import { useEstimate } from "../../store/estimate/useEstimate";

const Portability = () => {
  const { validCurrentStep, inValidCurrentStep } = useContext(StepsContext);
  const { portability, setPortability } = useEstimate();

  const select = (selection) => {
    setPortability(selection)
    validCurrentStep();
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-4 my-16">
        <div>
          <ContractCart
            title="Nouveau numéro"
            description="Le client souhaite un nouveau numéro"
            onSelect={() => select(false)}
            isSelected={portability !== null && !portability}
          />
        </div>
        <div>
          <ContractCart
            title="Numéro existant"
            description="Le client dispose d'un numéro. Saisir le RIO"
            onSelect={() => select(true)}
            isSelected={portability !== null && portability}
          />
        </div>
      </div>
    </>
  );
};

export { Portability };
