import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import compareDate from "date-fns/compareAsc";
import ProductGrid from "../../components/ProductGrid/ProductGrid.js";
import ShoppingBasket from "../../components/ShoppingBasket/ShoppingBasket.js";
import { ModalContext } from "../../contexts/productModalContext.js";
import useShoppingBasket from "../../store/Panier/Panier.js";
import { StepsContext } from "../../components/stepper/index.js";
import { useEstimate } from "../../store/estimate/useEstimate.js";
import { useAuthHeader } from "../../utils/auth/useAuthHeader";
import { Spinner } from "../../components/spinner/Spinner";
import { AlertContext } from "../../components/alerts";
import { extract } from "../../utils/Extract";
import { SearchBar } from "../../components/utils";

function ProductSelection() {
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasProductBeenInjected, setHasProductBeenInjected] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [search, setSearch] = useState("");
  const { openSelectedProduct } = useContext(ModalContext);
  const alertManager = useContext(AlertContext);
  const { validCurrentStep, inValidCurrentStep, goNextStep } = useContext(StepsContext);
  const { getAuthHeader, isLoading } = useAuthHeader();

  const shoppingBasketStore = useShoppingBasket();
  const { products: contractProducts, contractType, setProducts: setContractProducts, setTotalHT, setTotalTTC } = useEstimate();

  function handleClick(item) {
    window.open(item.product_file, "_blank");
  }

  function validate(totalHT, totalTTC) {
    if (shoppingBasketStore.products.length === 0) return;
    setContractProducts(shoppingBasketStore.products);
    setTotalHT(totalHT);
    setTotalTTC(totalTTC);
    validCurrentStep();
    goNextStep();
  }

  const LoadProducts = () => {
    async function getProducts(options) {
      return await axios.get(`${process.env.REACT_APP_API_URL}/wp-proxy/products/categories/${contractType.id}`, options);
    }
    return getAuthHeader()
      .then((authHeader) => {
        const options = { headers: authHeader };
        return getProducts(options);
      })
      .then((products) => {
        return products.data.sort((a, b) => {
          return compareDate(new Date(a.date), new Date(b.date));
        });
      });
  };

  useEffect(() => {
    if (!isLoading && contractType) {
      setLoading(true);
      LoadProducts()
        .then((products) => {
          setLoading(false);
          setProducts(products);
          setSortedData(products);
        })
        .catch(() => {
          setLoading(false);
          alertManager.riseAlert({
            title: "Oups ! Une erreur c'est produite",
            info: "Une erreur lors de la récuparation des données c'est produite. Rafraichir la page pour ré-éssayer (ctrl/cmd + r).",
          });
        });
    }

    if (contractProducts) validCurrentStep();
  }, [contractType, isLoading]);

  useEffect(() => {
    if (hasProductBeenInjected) return;
    if (!contractProducts || contractProducts.length === 0) {
      inValidCurrentStep();
      return;
    }
    if (JSON.stringify(contractProducts) === JSON.stringify(shoppingBasketStore.products)) {
      setHasProductBeenInjected(true);
      return;
    }

    shoppingBasketStore.bulk(contractProducts);
    setHasProductBeenInjected(true);
  }, [contractProducts, shoppingBasketStore]);

  useEffect(() => {
    if (products) {
      try {
        const filtered = products.filter((item) => {
          const stringItem = extract(item).join(" ").toLocaleLowerCase();
          return stringItem.includes(search.toLocaleLowerCase());
        });
        setSortedData(filtered);
      } catch (error) {
        console.log(error);
      }
    }
  }, [search]);

  useEffect(() => {
    if (!contractType || !products || products.length === 0 || contractType.slug !== process.env.REACT_APP_TFC_CLOUD_CONTRACT_NAME) return;

    products.some((item) => {
      if (item.name === process.env.REACT_APP_PRICE_TFC_CLOUD_CONTRACT) {
        if (shoppingBasketStore.products.some((el) => el.name === item.name)) return;

        shoppingBasketStore.addProduct(item);
      }
      return false;
    });
  }, [contractType, products]);

  useEffect(() => {
    if (!contractType || !products || products.length === 0 || !["contrat-pack-tpe", "contrat-tfcloud", "contrat-internet"].includes(contractType.slug)) return;

    products.some((item) => {
      if (item.name === "DETOXIOtest") {
        if (shoppingBasketStore.products.some((el) => el.name === item.name)) return;

        shoppingBasketStore.addProduct(item);
      }
      return false;
    });
  }, [contractType, products]);

  return (
    <>
      <div className="grid grid-cols-6 gap-4">
        <div className="w-full col-span-4">
          <div className="w-full mb-6">
            <SearchBar name="products" search={search} setSearch={setSearch} />
          </div>
          {loading && <Spinner />}
          <ProductGrid products={sortedData} openSelectedProduct={openSelectedProduct} onAddToCard={shoppingBasketStore.addProduct} onOpenFile={handleClick} colNumber={2} />
        </div>
        <div className="w-full col-span-2 px-4 ">
          <ShoppingBasket handleValidate={validate} />
        </div>
      </div>
    </>
  );
}

export { ProductSelection };
