import React, { useContext } from "react";
import { Button } from "../buttons";
import { StepsContext } from "./Stepper";
import { useValidSteps } from "./validSteps";

const StepFooter = ({ children }) => {
  const {
    allowGoToNext,
    goNextStep,
    allowGoToPrev,
    goPrevStep,
    isStepperValid,
    onStepperDone,
    hideNextButton,
    hidePrevButton
  } = useContext(StepsContext);
  const { resetValidsSteps } = useValidSteps()

  return (
    <footer className="flex py-2 mt-4">
      {!hidePrevButton && (
        <Button onClick={goPrevStep} disabled={!allowGoToPrev()}>Précédent</Button>
      )}

      <div className="flex flex-grow justify-end mx-2">
        {children}
      </div>

      <div className="flex flex-row-reverse">

        {!hideNextButton && (
          <>
            {!isStepperValid() && (
              <Button onClick={goNextStep} disabled={!allowGoToNext()}>Suivant</Button>
            )}
            {isStepperValid() && (
              <Button onClick={() => { resetValidsSteps(); onStepperDone !== undefined && onStepperDone(); }} classes="bg-green-500">Terminé</Button>
            )}
          </>
        )}

      </div>
    </footer>
  );
};

export { StepFooter };
