import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { AlertStack } from "./AlertStack";
import { useStateMap } from "../../utils/useStateMap";

const AlertContext = createContext();

export const ERROR_ALERT = "ERROR_ALERT";
export const SUCCESS_ALERT = "SUCCESS_ALERT";

const AlertStackContext = ({ children }) => {
  const [alerts, setAlerts] = useState([]);
  const [dissmisQueue, setAutoDissmis, getAutoDismiss] = useStateMap();

  // TODO add ability to configure timeout
  /**
   * Check and format a Alert object. it represent a unique alert. This function should only be used internally.
   * @param {object\string} alert - a string or an object with title and info represent alert data
   * @returns { Alert } - Return a valid Alert obejct
   */
  const _createAlert = (alert) => {
    let newAlert = {};

    if (!alert) throw Error("alert param must be provided");

    if (typeof alert === "string") {
      newAlert.title = alert;
      newAlert.id = Date.now().valueOf().toString();
      newAlert.type = ERROR_ALERT;
      return newAlert;
    }

    if (typeof alert === "object" && alert.title && !alert.type) {
      newAlert = Object.assign({}, alert);
      newAlert.id = Date.now().valueOf().toString();
      newAlert.type = ERROR_ALERT;
      return newAlert;
    }

    if (typeof alert === "object" && alert.title && alert.type) {
      newAlert = Object.assign({}, alert);
      newAlert.id = Date.now().valueOf().toString();
      newAlert.type = alert.type;
      return newAlert;
    }

    throw Error(
      'Alert must be a string or an object with at least a "title" props'
    );
  };

  /**
   * Set a timeout to auto dissmis alert afert certain time. This function should only be used internally.
   * @param {Alert} alert - Alert to self-dismiss
   * @param {number} timeout - The time in milliseconds after which the alert should self-dismiss.
   * @return {number} AN id to clear the self-dismiss
   */
  const _autoDismiss = (alert, timeout) => {
    return setTimeout(() => {
      setAlerts((alerts) => alerts.filter((alt) => alt.id !== alert.id));
    }, timeout | 5000);
  };

  /**
   * Clear all self-disimiss registred. This function should only be used internally.
   */
  const _clearAllSelfDismiss = () => {
    dissmisQueue.forEach((autoDismissId) => {
      clearTimeout(autoDismissId);
    });
  };

  /**
   * Rise an alert to the use. This alert will be self-dismiss afert 5 seconds.
   * @param {Alert} alert - The Alert to rise
   */
  const riseAlert = (alert) => {
    const _alert = _createAlert(alert);
    setAlerts((alerts) => {
      return [...alerts, _alert];
    });
    setAutoDissmis(_alert.id, _autoDismiss(_alert));
  };

  /**
   * Dismiss a rised Alert before self-dismiss. This function automaticaly clear the self-dismiss process.
   * @param {Alert} alert Alert to dismiss
   */
  const dismissAlert = (alert) => {
    clearTimeout(getAutoDismiss(alert.id));
    setAlerts((alerts) => alerts.filter((alt) => alt.id !== alert.id));
  };

  useEffect(() => {
    return () => _clearAllSelfDismiss();
  }, []);

  return (
    <AlertContext.Provider value={{ riseAlert, dismissAlert }}>
      {children}
      <AlertStack alerts={alerts} onDismiss={dismissAlert} />
    </AlertContext.Provider>
  );
};

export { AlertStackContext, AlertContext };
