/**
 * Button componant
 * version: 1.0.1
 * Author: Nicolas Liveris
 * Date: Octobre 2020
 */

import React from "react";
import classNames from "classnames";

const Button = ({ children, classes, onClick, disabled, type = "text", color, hoverColor, focusColor, ...args }) => {

  const defaultColor = 'blue-500'
  const defaultHoverColor = 'gray-700'
  const defaultFocusColor = 'gray-700'

  const cls = classNames(
    "px-3 py-2 rounded-md text-xs focus:outline-none text-white font-semibold mx-2 first:ml-0 last:mr-0",
    `bg-${color || defaultColor}`,
    `hover:bg-${hoverColor || defaultHoverColor}`,
    `focus:bg-${focusColor || defaultFocusColor}`,
    classes,
    {
      "opacity-50 cursor-not-allowed": disabled,
    }
  );

  return (
    <button
      onClick={(e) => {
        !disabled && onClick && onClick(e);
      }}
      className={cls}
      type={type}
      {...args}
    >
      {children}
    </button>
  );
};

export { Button }
